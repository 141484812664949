import React, { useState, useEffect, useRef } from "react"
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import mp4Icon from './../../images/mp4.png';
import mp3Icon from './../../images/mp3.png';
import ytIcon from './../../images/yt.png';
import AttachmentIcon from './../../images/attachment.png';
import PlaceholderImg from './../../images/placeholder-img.png';
import FocusTrap from 'focus-trap-react';
import Loader from './../../components/loader/Loader';
import Button from './.././button/Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Input from './../../components/input/Input';

export default props => {
    let [ error, setError ] = useState( false );
    let [ load, setLoad ] = useState( false );
    let [ errorText, setErrorText ] = useState( false );
    let [ popup, setPopup ] = useState(false);
    let [ images, setImages ] = useState([]);
    let [ pageImage, setPageImage ] = useState(1);
    let [ lastPageImage, setLastPageImage ] = useState(false);
    let itemsOnPage = 30;
    let scrollRef = useRef();
    let [ loader, setLoader ] = useState(false);
    let [ selectedIndex, setSelectedIndex ] = useState([]);
    let [ searchValue, setSearchValue ] = useState("");

    useEffect(() => {
        if ( !load ) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
      if ( load ) {
          validate();
      }
    }, [props.forceValidate])

    const validate = () => {
        let error = false;
        if(props.value && (props.value).length == 0 || !props.value) {
            setError(true)
            setErrorText('Należy wybrać media');
            error = true;
        }else{
            setError(false);
            setErrorText('');
            error = false;
        }

        if(props.setError) {
            props.setError(error);
        }
    }

    const getImages = (newCurrentPage) => {
        setLoader(true);
        let search = searchValue;
        if(search) {
            search = search.length > 0 ? search : null;
        }else{
            search = null;
        }
        if(!newCurrentPage) {
            newCurrentPage = pageImage
        }
        let type = props.only ? props.only : null;
        axios({
            method: 'get',
            url: `${ApiUrl()}/galleryItemsPagination?currentPage=${newCurrentPage}&type=${type}&pageSize=${itemsOnPage}&search=${search}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` },
        }).then(response => {
            let res = JSON.parse(response.request.response);
            let newImages = [...images, ...res.data];
            if(newCurrentPage == 1) {
                newImages = res.data;
            }
            // if(search) {
            //     setImages(res.data);
            // }else{
            //     setImages(newImages);
            // }
            setImages(newImages);
            setLoad(false);
            if(!lastPageImage) {
                setLastPageImage(res.meta.last_page);
            }
            if(props.type == "gallery") {
                checkImagesClass(newImages);
            }
            setLoader(false);
        })
    }

    useEffect(() => {
        if(popup && images && images.length == 0) {
            getImages();
        }
    }, [popup])

    const scrollMedia = () => {
        let positionYScroll = scrollRef.current.scrollTop;
        let mediaBox = scrollRef.current;
        let maxScroll = mediaBox ? mediaBox.scrollHeight - mediaBox.offsetHeight : false;
        positionYScroll = Math.floor(positionYScroll);
        maxScroll = maxScroll ? Math.floor(maxScroll) : false;
        if(maxScroll) {
            if(positionYScroll == maxScroll) {
                if(lastPageImage > pageImage) {
                    setPageImage(++pageImage)
                    setLoader(true);
                    getImages();
                }
            }
        }
    }

    const action = (index, img, remove = false) => {
        if(remove) {
            setSelectedIndex([]);
            props.setFunction("");
        }
        if(!props.type) {
            props.setFunction(img); 
            setPopup(false)
        }else{
            if(selectedIndex.includes(index)) {
                let copySelectedIndex = selectedIndex;
                copySelectedIndex = copySelectedIndex.filter((item, i) => item !== index ? item : false);
                setSelectedIndex(copySelectedIndex);
                props.removeItem(images[index].id);
            }else{
                let copySelectedIndex = selectedIndex;
                copySelectedIndex = [...copySelectedIndex, index];
                setSelectedIndex(copySelectedIndex);
                props.setFunction(img);
            }
        }
    }

    const checkImagesClass = (allImages, emptyIndex = false) => {
        let indexList = selectedIndex;
        if(emptyIndex) {
            indexList = [];
        }
        allImages && allImages.map((image, index) => {
            props.items && props.items.map(item => {
                if(item.image && item.image.id == image.id) {
                    indexList = [...indexList, index];
                }
            })
        })
        setSelectedIndex(indexList);
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter' || e.key == 'Escape') {
            functionName(functionValue)
        }
    }

    const handleKeyDownSecond = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        // const items = Array.from(props.value);
        const items = props.value;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.changeRDLO(items);
    }

    const removeItem = (id) => {
        props.removeItem(id);
    }
    
  return (
    <div className={error ? "media-load error" : "media-load" + (" " + (props.value && props.value.src ? props.value.src.split('.').pop().substring(0, 3) : ""))} data-alert={errorText}>
        {props.withRemove && (props.value && props.value.id) &&
           <i className="material-icons" 
                onClick={() => action(false, false, true)}
                aria-label="Usuń media"
                tabIndex="0" 
                role="button" 
                onKeyDown={(e) => handleKeyDown(e, action, false, false, true)}>close</i>
        }
        {props.label &&
            <label>{props.label}</label>
        }
        {props.value && props.value.sizes && props.value.sizes.small && props.type !== "gallery" &&
            <>  
                {(props.value.type == "image" || props.value.type == "vector") &&
                    <img src={props.value.sizes.small} className={props.disableBtn ? "preview preview-cursor" : "preview"} alt={props.value.alts[0]?.value} title={props.value.name} onClick={() => props.disableBtn && setPopup(true) } onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} tabIndex="0" role="button" />
                }
                {props.value.type == "video" &&
                    <img src={mp4Icon} alt={"ikona mp4"} className={props.disableBtn ? "preview preview-cursor" : "preview"} alt="preview" title={props.value.name} onClick={() => props.disableBtn && setPopup(true) } onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} tabIndex="0" role="button" />
                }
                {props.value.type == "lector" &&
                    <img src={mp3Icon} alt={"ikona mp3"} className={props.disableBtn ? "preview preview-cursor" : "preview"} alt="preview" title={props.value.name} onClick={() => props.disableBtn && setPopup(true) } onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} tabIndex="0" role="button" />
                }
                {props.value.type == "video-url" &&
                    <img src={ytIcon} alt={"ikona youtube"} className={props.disableBtn ? "preview preview-cursor" : "preview"} alt="preview" title={props.value.name} onClick={() => props.disableBtn && setPopup(true) } onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} tabIndex="0" role="button" />
                }
                {props.value.type == "attachment" &&
                    <img src={AttachmentIcon} alt={"ikona załącznika"} className={props.disableBtn ? "preview preview-cursor" : "preview"} alt="preview" title={props.value.name} onClick={() => props.disableBtn && setPopup(true) } onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} tabIndex="0" role="button" />
                }
            </>
        }
        {props.type == "gallery" &&
        <DragDropContext onDragEnd={handleRLDDChange}>
            <Droppable droppableId="section-items" direction="horizontal">
                {(provided) => (
                    <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.value && props.value.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="gallery-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="images-box" {...provided.dragHandleProps} tabIndex="-1">
                                            {item.image && item.image.sizes &&
                                                <div className="image-wrapper">
                                                    <i className="material-icons" 
                                                        onClick={() => removeItem(item.image.id)}
                                                        aria-label="Usuń media"
                                                        tabIndex="0" 
                                                        role="button" 
                                                        onKeyDown={(e) => handleKeyDown(e, removeItem, item.image.id)}>close</i>
                                                    <img src={item.image.sizes.small} alt={item.image.alt} title={item.image.name} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        }
        {!props.disableBtn &&
            <div className="btn-media">
                <Button title="Wybierz media" class="btn-secondary" icon="add" setFunction={() => setPopup(true)} />
            </div>
        }
        {props.disableBtn && (props.value && (props.value).length == 0 || !props.value) &&
            <img src={PlaceholderImg} 
                alt="place" 
                className="preview preview-cursor"
                onClick={() => props.disableBtn && setPopup(true) } 
                onKeyDown={(e) => props.disableBtn && handleKeyDownSecond(e, setPopup, true)} 
                tabIndex="0" 
                role="button" 
            />
        }
        {error && errorText &&
            <span className="error-text">{errorText}</span>
        }
        {popup &&
        <FocusTrap>
            <div className="media-popup">
                {loader &&
                    <div className="loader-wrapper">
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                }
                <div className="search-wrapper">
                    <Input  
                        type={ "search" }
                        required={ false }
                        setFunction={ val => setSearchValue(val) }
                        onClickEnter={ () => getImages(1) }
                        value={ searchValue }
                        placeholder={"Wyszukaj"}
                        ariaLabel="Wyszukaj media po nazwie"
                        regExp={ /.*/ }
                        minChar={ 0 } 
                    />
                </div>
                <div className="close" 
                    tabIndex="0" 
                    role="button" 
                    onClick={() => setPopup(false)}
                    onKeyDown={(e) => handleKeyDown(e, setPopup, false)}>Zamknij</div>
                <div className="images-media" id="images-media" onScroll={() => scrollMedia()} ref={scrollRef}>
                    {images && images.map((img, index) => (
                        <div tabIndex="0" className={"image " + (img.src.split('.').pop().substring(0, 3)) + (selectedIndex.includes(index) ? " active" : "")} key={img.id} onClick={(e) => action(index, img) } onKeyDown={(e) => handleKeyDownSecond(e, action, index, img)}>
                            {(img.type == "image" || img.type == "vector") &&
                                <img src={img.sizes.small} alt={img.alt} title={img.name} />
                            }
                            {img.type == "video" &&
                                <img src={mp4Icon} className="bg-svg" alt={"mp4"} title={img.name} />
                            }
                            {img.type == "lector" &&
                                <img src={mp3Icon} className="bg-svg" alt={"mp3"} title={img.name} />
                            }
                            {img.type == "video-url" &&
                                <img src={ytIcon} className="bg-svg" alt={"youtube"} title={img.name} />
                            }
                            {img.type == "attachment" &&
                                <img src={AttachmentIcon} className="bg-svg" alt={"attachment"} title={img.name} />
                            }
                            <div className="simple-info-image">
                                <div className="info-row">
                                    <span className="label">Nazwa: </span>
                                    <span className="value">{img.name}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </FocusTrap>
        }
    </div>
  )
}
