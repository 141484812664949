//change data section before save
const FilterRelationSections = (sections) => {
    let copySections = sections;
    copySections = copySections.map((item, i) => {
        let newData = item.data;
        if(item.section == "box_read_more_section") {
            newData.boxes = [];
        }else if(item.section == "box_header_links_section") {
            newData.linksPages = [];
        }else if(item.section == "logos_inline_section") {
            newData.logos = [];
        }else if(item.section == "files_download_section") {
            newData.files = [];
        }else if(item.section == "brands_section") {
            newData.brands = [];
        }else if(item.section == "collections_section") {
            newData.collections = [];
        }else if(item.section == "accessories_section") {
            newData.accesories = [];
        }else if(item.section == "technical_desc_section") {
            newData.items = [];
        }else if(item.section == "pages_list_section") {
            newData.linksPages = [];
        }
        item.data = newData;
        return item;
    })
    return copySections;
}

export default FilterRelationSections;