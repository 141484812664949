import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import TriangleIcon from './../../../svg-components/Arrow';
import { useSelector, useDispatch } from 'react-redux'
import MenuItem from './../../../components/menu-item/MenuItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../../../components/button/Button';

const Menus = () => {
    let [ load, setLoad ] = useState(false);
    let [ contentCreateMenu, setContentCreateMenu ] = useState(false);
    let [ newMenuName, setNewMenuName ] = useState('');
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let menus = useSelector(state => state.menus);
    let [ pages, setPages ] = useState([]);
    let postTypes = useSelector(state => state.postTypes);
    let [ activeMenu, setActiveMenu ] = useState(0);
    let [ openedIndexPostTypes, setOpenedIndexPostTypes ] = useState([]);
    let [ menusData, setMenusData ] = useState([]);
    let [ rendering, setRendering ] = useState(null);
    let [ newNumber, setNewNumer ] = useState(0);
    let [ customLink, setCustomLink ] = useState({
        url: '',
        title: '',
    })
    let [ removeMenusItems, setRemoveMenusItems ] = useState([]);
    let [ activeParentAdd, setActiveParentAdd ] = useState(null);
    let lang = useSelector(state => state.lang);
    const dispatch = useDispatch();

    useEffect(() => {
        if(menus.length > 0 && !load) {
            getPages();
            createMenusDataOnLoad();
            setLoad(true);
        }
    }, [load, menus])

    useEffect(() => {
        if(lang && load) {
            setLoad(false);
        }
    }, [lang])

    const getPages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesListOld?langID=${lang.id}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setPages(res);
        })
    }
    const createMenusDataOnLoad = (data = false) => {
        let allMenus = data ? data : menus
        let newMenus = []
        newMenus = allMenus.filter(menu => menu.language.id == lang.id ? menu : false);
        newMenus && newMenus.map((menu, index) => {
            menu.items && menu.items.map((item, i) => {
                let newItem = {
                    id: item.id,
                    page_id: item.page_id,
                    category: item.category,
                    post_type: item.post_type,
                    slug: item.slug,
                    target: item.target,
                    title: item.title,
                    url: item.url,
                    childrens: item.childrens,
                    parent: item.parent,
                    update: false,
                }
                newMenus[index].items[i] = newItem;
            })
        })
        setMenusData(newMenus);
    }

    const changeContent = () => {
        setContentCreateMenu(!contentCreateMenu);
    }

    const createNewMenu = () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            let errors = false;
    
            if(newMenuName.length == 0) {
                errors = true;
            }
    
            if(errors) {
                setBlockedBtn(false);
            }
    
            if(!errors) {
                axios({
                    method: 'post',
                    url: `${ApiUrl()}/createMenu`,
                    data: {
                        token: localStorage.getItem('token'),
                        menuName: newMenuName,
                        userID: localStorage.getItem('userId'),
                        langID: lang.id,
                    }
                }).then(response => {
                    let res = JSON.parse(response.request.response);
                    if(res.status = "success") {
                        let newMenusData = menus;
                        if(res.data && (res.data).length > 0) {
                            newMenusData = [...newMenusData, res.data[0]];
                            dispatch({ type: 'MENUS', value: newMenusData })
                            createMenusDataOnLoad(newMenusData);
                        }
                        setBlockedBtn(false);
                        setContentCreateMenu(false);
                    }else{
                        setBlockedBtn(false);
                        dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                    }
                })
                document.getElementById('newMenuName').classList.remove('error');
            }else{
                document.getElementById('newMenuName').classList.add('error');
            }
        }
    }

    const getPostTypeName = (postTypeSlug) => {
        let result = postTypeSlug;
        // console.log(postTypes);
        // if(postTypes) {
        //     postTypes.map(postType => {
        //         if(postType.slug == postTypeSlug) {
        //             result = postType.display_name;
        //         }
        //     })
        // }
        if(result == "pages") {
            result = "Strony";
        }else if(result == "products") {
            result = "Produkty";
        }
        return result;
    }

    const togglePostTypes = (index) => {
        let status = openedIndexPostTypes.includes(index);
        if(status) {
            let newOpened = openedIndexPostTypes;
            let spliceIndex = newOpened.indexOf(index);
            newOpened = newOpened.filter((item, i) => i !== spliceIndex);
            setOpenedIndexPostTypes(newOpened)
        }else{
            let newOpened = openedIndexPostTypes;
            newOpened = [...newOpened, index];
            setOpenedIndexPostTypes(newOpened);
        }
    }

    const handleRLDDChange = (result, parentIndex) => {
        let newData = menusData;
        if (!result.destination) return;
        if(result.type === "menuItems") {
            const items = Array.from(newData[activeMenu].items);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            newData[activeMenu].items = items;
        }else if(result.type === "menuChildren") {
            const items = Array.from(newData[activeMenu].items[parentIndex].childrens);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            newData[activeMenu].items[parentIndex].childrens = items;
        }
        setMenusData(newData);
        setRendering(Math.random())
    }

    const addItemToMenus = (page) => {
        let newMenusItems = menusData;
        let newItem;
        if(activeParentAdd == null) {
            if(page) {
                newItem = {
                    id: `new-${newNumber}`,
                    page_id: page.id,
                    menu_id: menusData[activeMenu].id,
                    post_type: page.post_type,
                    category: page.category,
                    slug: page.slug,
                    target: false,
                    title: page.title,
                    childrens: [],
                    parent: null,
                    update: true,
                }
            }else{
                newItem = {
                    id: `new-${newNumber}`,
                    page_id: page.id,
                    menu_id: menusData[activeMenu].id,
                    category: page.category,
                    post_type: {
                        id: 0,
                        display_name: "Link zewnętrzny",
                        slug: "custom-link"
                    },
                    target: false,
                    title: customLink.title,
                    url: customLink.url,
                    childrens: [],
                    parent: null,
                    update: true,
                }
            }
            let newItems = [...newMenusItems[activeMenu].items, newItem];
            newMenusItems[activeMenu].items = newItems;
        }else{
            if(page) {
                newItem = {
                    id: `new-${newNumber}`,
                    page_id: page.id,
                    menu_id: menusData[activeMenu].id,
                    post_type: page.post_type,
                    category: page.category,
                    slug: page.slug,
                    target: false,
                    title: page.title,
                    childrens: [],
                    parent: menusData[activeMenu].items[activeParentAdd].id,
                    update: true,
                }
            }else{
                newItem = {
                    id: `new-${newNumber}`,
                    menu_id: menusData[activeMenu].id,
                    post_type: {
                        id: 0,
                        display_name: "Link zewnętrzny",
                        slug: "custom-link"
                    },
                    category: page.category,
                    target: false,
                    title: customLink.title,
                    url: customLink.url,
                    childrens: [],
                    parent: menusData[activeMenu].items[activeParentAdd].id,
                    update: true,
                }
            }
            let newItems = [...newMenusItems[activeMenu].items, newItem];
            newMenusItems[activeMenu].items = newItems;
            //add children
            let newChildrensItemMenu = [...newMenusItems[activeMenu].items[activeParentAdd].childrens, newItem];
            newMenusItems[activeMenu].items[activeParentAdd].childrens = newChildrensItemMenu;
        }
        setMenusData(newMenusItems);
        setNewNumer(newNumber + 1);
        setRendering(Math.random())
        if(!page) {
            setCustomLink({
                url: '',
                title: '',
            })
        }
    }

    const validateCustomLink = () => {
        let errors = false;
        if((customLink.title).length == 0) {
            document.getElementById('customTitle').classList.add('error');
            errors = true;
        }else{
            document.getElementById('customTitle').classList.remove('error');
        }
        if((customLink.url).length == 0) {
            document.getElementById('customUrl').classList.add('error');
            errors = true;
        }else{
            document.getElementById('customUrl').classList.remove('error');
        }
        if(!errors) {
            addItemToMenus(false)
        }
    }

    const removeItemMenu = (item, itemIndex, itemChildrenIndex) => {
        let newArray = menusData
        let newItems;
        if(itemChildrenIndex == null) {
            newItems = (newArray[activeMenu].items).filter((itemMenu, i) => i !== itemIndex);
            newArray[activeMenu].items = newItems;
        }else{
            newItems = (newArray[activeMenu].items).filter(itemMenu => item.id !== itemMenu.id);
            newArray[activeMenu].items = newItems;

            let childrensList = newArray[activeMenu].items[itemIndex].childrens.filter((itemMenu, i) => i !== itemChildrenIndex);
            newArray[activeMenu].items[itemIndex].childrens = childrensList;
        }

        setMenusData(newArray);
        if(!(String(item.id)).includes('new')) { 
            setRemoveMenusItems([...removeMenusItems, item.id])
        }
        setRendering(Math.random())
    }

    const updateMenusItem = (label, newValue, itemIndex, itemChildrenIndex) => {
        let newArrayMenus = menusData;
        let newMenuItems = null;
        if(itemChildrenIndex == null) {
            newMenuItems = (newArrayMenus[activeMenu].items).filter((item, i) => i === itemIndex ? (item[label] = newValue, item['update'] = true) : item);
            newArrayMenus[activeMenu].items = newMenuItems;
        }else{
            let updateChildrenID;
            newMenuItems = (newArrayMenus[activeMenu].items[itemIndex].childrens).filter((item, i) => i === itemChildrenIndex ? (item[label] = newValue, item['update'] = true, updateChildrenID = item['id']) : item);
            newArrayMenus[activeMenu].items[itemIndex].childrens = newMenuItems;
            //update childrens in parent menu
            newArrayMenus[activeMenu].items.filter(item => item.id == updateChildrenID ? (item[label] = newValue, item['update'] = true) : item);
        }
        setMenusData(newArrayMenus);
        setRendering(Math.random())
    }

    const save = () => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/saveMenus`,
            data: {
                token: localStorage.getItem('token'),
                menus: menusData,
                removeMenusItems: removeMenusItems,
                userID: localStorage.getItem('userId'),
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(res.status = "success") {
                dispatch({ type: 'MENUS', value: res.data })
                createMenusDataOnLoad(res.data)
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Menu zostało zapisane.</p>` }) 
            }else{
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
        })
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    return (
        <div className="menus-section">
            <div className="container">
                <div className="welcome">
                    <h1>Menu</h1>
                    <Button title={contentCreateMenu ? 'Wróć do listy menu' : 'Stwórz nowe menu'} class="btn-secondary" icon="add" setFunction={ () => changeContent() } />
                </div>
                <div className="menus-wrapper">
                    <div className="col">
                        <div className="pages-types">
                            {pages && Object.keys(pages).map((postTypeSlug, i) => (
                                <div key={i} className={openedIndexPostTypes.includes(i) ? "type active" : "type"}>
                                    <div className="header" 
                                        onClick={() => togglePostTypes(i)} 
                                        role="button" 
                                        tabIndex="0" 
                                        aria-label={openedIndexPostTypes.includes(i) ? `Zwiń ${getPostTypeName(postTypeSlug)}` : `Rozwiń ${getPostTypeName(postTypeSlug)}`} 
                                        onKeyDown={(e) => handleKeyDown(e, togglePostTypes, i)}>
                                        <span>{getPostTypeName(postTypeSlug)}</span>
                                        <div className="svg-icon">
                                            <TriangleIcon />
                                        </div>
                                    </div>
                                    <div className="pages">
                                        <ul>
                                            {pages[postTypeSlug].map(page => (
                                                <li>
                                                    <p onClick={() => addItemToMenus(page)} 
                                                    role="button" 
                                                    tabIndex={openedIndexPostTypes.includes(i) ? "0" : "-1"}
                                                    aria-label={`Dodaj do menu ${page.title}`} 
                                                    onKeyDown={(e) => handleKeyDown(e, addItemToMenus, page)}>{page.title}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                            <div className={openedIndexPostTypes.includes(9999) ? "type active" : "type"}>
                                <div className="header" 
                                    onClick={() => togglePostTypes(9999)} 
                                    role="button" 
                                    tabIndex="0" 
                                    aria-label={openedIndexPostTypes.includes(9999) ? `Zwiń Zewnętrzny odnośnik` : `Rozwiń Zewnętrzny odnośnik`} 
                                    onKeyDown={(e) => handleKeyDown(e, togglePostTypes, 9999)}>
                                    <span>Zewnętrzny odnośnik</span>
                                    <div className="svg-icon">
                                        <TriangleIcon />
                                    </div>
                                </div>
                                <div className="pages">
                                    <div className="input-row">
                                        <label for="customUrl">Adres URL</label>
                                        <input className="input-menu" id="customUrl" type="text" tabIndex={openedIndexPostTypes.includes(9999) ? "0" : "-1"} onChange={(e) => setCustomLink({
                                            title: customLink.title, 
                                            url: e.target.value
                                        })} value={customLink.url} />
                                    </div>
                                    <div className="input-row">
                                        <label for="customTitle">Tekst Odnośnika</label>
                                        <input className="input-menu" id="customTitle" type="text" tabIndex={openedIndexPostTypes.includes(9999) ? "0" : "-1"} onChange={(e) => setCustomLink({
                                            title: e.target.value,
                                            url: customLink.url
                                        })} value={customLink.title} />
                                    </div>
                                    <div className="input-row action-btn-add">
                                        <div className="add action-btn" 
                                            onClick={() => validateCustomLink() }
                                            role="button" 
                                            tabIndex={openedIndexPostTypes.includes(9999) ? "0" : "-1"}
                                            onKeyDown={(e) => handleKeyDown(e, validateCustomLink)}>
                                            Dodaj do menu
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="menus-list">
                            <div className="menus">
                                {menusData && menusData.map((menu, i) => (
                                    // <div className={i == activeMenu ? "menu-type active" : "menu-type"} 
                                    <div className="menu-type"
                                        key={menu.id} 
                                        onClick={() => setActiveMenu(i)}
                                        role="button" 
                                        tabIndex="0" 
                                        onKeyDown={(e) => handleKeyDown(e, setActiveMenu, i)}>
                                        <p className={i == activeMenu ? "active" : ""}>{menu.name}</p>
                                    </div>
                                ))}
                            </div>
                            {!contentCreateMenu && 
                            <>
                                <DragDropContext onDragEnd={handleRLDDChange}>
                                    <Droppable droppableId="sections-component" type="menuItems">
                                    {(provided) => (
                                    <div className="menu-items" {...provided.droppableProps} ref={provided.innerRef}>
                                        {menusData && menusData[activeMenu] && menusData[activeMenu].items && menusData[activeMenu].items.map((item, itemIndex) => (
                                            <>
                                            {!item.parent &&
                                                <Draggable key={String(item.id)} draggableId={String(item.id)} index={itemIndex}>
                                                    {(provided) => provided && (
                                                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} tabIndex="-1" aria-hidden={true}>
                                                            <MenuItem item={item} itemIndex={itemIndex} removeItemMenu={(item) => removeItemMenu(item, itemIndex, null)} updateItem={(label, newValue) => updateMenusItem(label, newValue, itemIndex, null)} changeActiveParentAdd={(status) => setActiveParentAdd(status)} activeParentAdd={activeParentAdd} />
                                                            {item.childrens && (item.childrens).length > 0 &&
                                                            <div className="children">
                                                                <DragDropContext onDragEnd={(result) => handleRLDDChange(result, itemIndex)}>
                                                                    <Droppable droppableId="sections-component-children" type="menuChildren">
                                                                        {(provided) => (
                                                                            <div className="menu-items" {...provided.droppableProps} ref={provided.innerRef}>
                                                                                {item.childrens.map((itemChildren, itemChildrenIndex) => (
                                                                                    <Draggable key={String(itemChildren.id)} draggableId={String(itemChildren.id)} index={itemChildrenIndex}>
                                                                                        {(provided) => provided && (
                                                                                            <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} tabIndex="-1" aria-hidden={true}>
                                                                                                <MenuItem item={itemChildren} itemIndex={itemChildrenIndex} removeItemMenu={(item) => removeItemMenu(item, itemIndex, itemChildrenIndex)} updateItem={(label, newValue) => updateMenusItem(label, newValue, itemIndex, itemChildrenIndex)} activeParentAdd={"children"} />
                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                </DragDropContext>
                                                            </div>
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            }
                                            </>
                                        ))}
                                    </div>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                                <div className="btn-save">
                                    <div className="add action-btn" 
                                        onClick={() => save()}
                                        role="button" 
                                        tabIndex="0" 
                                        onKeyDown={(e) => handleKeyDown(e, save)}>
                                        Zapisz
                                    </div>
                                </div>
                            </>
                            }
                            {contentCreateMenu &&
                                <div className="create-menu">
                                    <input id="newMenuName" type="text" value={newMenuName} placeholder="nazwa" onChange={(e) => setNewMenuName(e.target.value)} className="input-menu" />
                                    <div className="btn-save more-margin">
                                        <div className="add action-btn" 
                                            onClick={() => createNewMenu()}
                                            role="button" 
                                            tabIndex="0" 
                                            onKeyDown={(e) => createNewMenu(e)}>
                                            Utwórz
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    );
}

export default Menus;