import React, { useState, useEffect, useRef } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from './../select/Select';
import axios from 'axios';
import ApiUrl from './../../services/ApiUrl';
import Loader from './../loader/Loader';
import Input from './../../components/input/Input';

const SelectItems = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let [ selected, setSelected ] = useState([]);
    let [ data, setData ] = useState([]);
    let [ selectedCategory, setSelectedCategory ] = useState();
    let [ error, setError ] = useState(false);
    let [ optionsCategory, setOptionsCategory ] = useState([]);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ lastPage, setLastPage ] = useState(1);
    let [ loader, setLoader ] = useState(true);
    let [ filterStartData, setFilterStartData ] = useState(false);
    let [ searchVal, setSearchVal ] = useState("");
    let scrollRef = useRef()
    let perPage = 10;
    
    useEffect(() => {
        if(props.value && (props.value).length > 0) {
            setSelected(props.value)
        }
    }, [props.value])

    useEffect(() => {
        if(postType && !load) {
            getPages(currentPostType());
            setLoad(true);
        }
    }, [load, postType])

    const currentPostType = () => {
        if(!props.postType) {
            if(postType == "collections") {
                if(props.label == "Opisy techniczne") {
                    return "technical-description";
                }
                if(props.label == "Kolory") {
                    return "colors";
                }
            }
            if(postType == "products") {
                return "brands";
            }
            if(postType == "colors") {
                return "products";
            }
        }else{
            return props.postType;
        }
    }

    const getPages = (getPostType, getPage = 1) => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${props.langID}&postType=${getPostType}&page=${getPage}&perPage=${perPage}&searchVal=${searchVal}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            if(getPostType != "all") {
                setLastPage(res.meta.last_page);
                setCurrentPage(res.meta.current_page)
                // setData([...data, ...res.data]);
                if(getPage == 1) {
                    setData(res.data);
                }else{
                    setData([...data, ...res.data]);
                }
                setLoader(false);
            }else{
                setFilterStartData(res);
                setLastPage(res.pages.last_page);
                setCurrentPage(res.pages.current_page)
                setData(res.pages.data)
                generatecategory(res);
                setLoader(false);
            }
        })
    }

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        if ( selected && selected.length === 0) {
            // setErrorText('To pole jest wymagane.')
            setError(true);
            return false;
        } else {
            setError(false);
        }

        return true;
    }

    const generatecategory = (res) => {
        setSelectedCategory(0)
        if(props.filter) {
            let categoryList = [];
            Object.keys(res).map((label, i) => {
                if(i == 0) {
                    setSelectedCategory(label)
                }
                categoryList = [...categoryList, {
                    value: label,
                    text: changeText(label),
                }]
            })
            setOptionsCategory(categoryList);
        }
    }

    const changeText = (text) => {
        if(text == "pages") {
            text = "Strony";
        }else if(text == "articles") {
            text = "Wpisy";
        }else if(text == "products") {
            text = "Produkty";
        }else if(text == "brands") {
            text = "Marki";
        }else if(text == "colors") {
            text = "Kolory";
        }else if(text == "collections") {
            text = "Kolekcje";
        }else if(text == "technical-description") {
            text = "Opisy techniczne";
        }else if(text == "marketing-support") {
            text = "Marketing support";
        }else if(text == "realizations") {
            text = "Realizacje";
        }else if(text == "accessories") {
            text = "Akcesoria";
        }

        return text;
    }

    const addSelected = (item) => {
        if(props.type == "category") {
            if(props.limit > selected.length || !props.limit) {
                let status = true;
                selected && selected.filter(sel => {
                    if(sel.id == item.id && sel.type == item.type) {
                        status = false;
                    } 
                })
        
                if(status) {
                    setSelected([...selected, item])
                    props.handle([...selected, item])
                }
            }
        }else{
            if(props.limit > (!props.value ? 0 : (props.value).length) || !props.limit) {
                let status = true;
                selected && selected.filter(sel => {
                    if(sel.id == item.id && sel.type == item.type) {
                        status = false;
                    } 
                })
        
                if(status) {
                    setSelected([...selected, item])
                    props.handle([...selected, item])
                }
            }
        }
    }

    const removeSelected = (item) => {
        let newSelected = selected.filter(sel => {
            if(sel.id !== item.id) {
                return sel;
            }else{
                if(sel.type !== item.type) {
                    return sel;
                }
            }
        })
        setSelected(newSelected)
        props.handle(newSelected)
    }

    const checkClass = (itemData) => {
        let newClass = ""
        selected.filter(item => {
            if(item && itemData && item.id == itemData.id && item.type == itemData.type) {
                newClass = "disabled";
            }
        })
        return newClass;
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(selected);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.handle(items)
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const scrollPages = () => {
        let positionYScroll = scrollRef.current.scrollTop;
        let mediaBox = scrollRef.current;
        let maxScroll = mediaBox ? mediaBox.scrollHeight - mediaBox.offsetHeight : false;
        positionYScroll = Math.floor(positionYScroll);
        maxScroll = maxScroll ? Math.floor(maxScroll) : false;
        if(maxScroll) {
            if(positionYScroll >= maxScroll) {
                if(lastPage > currentPage) {
                    if(!loader) {
                        setLoader(true);
                        if(props.postType == "all") {
                            getPages(selectedCategory, currentPage + 1);
                        }else{
                            getPages(currentPostType(), currentPage + 1);
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        if(load) {
            let newRes = filterStartData[selectedCategory];
            setLoader(true);
            setSearchVal("");
            setLastPage(newRes?.last_page);
            setCurrentPage(newRes?.current_page)
            setData(newRes?.data)
            setLoader(false);
        }
    }, [selectedCategory])

    const search = () => {
        setLoader(true);
        if(props.postType == "all") {
            getPages(selectedCategory, 1);
        }else{
            getPages(currentPostType(), 1);
        }
    }

    return (
        <div className={"select-items-section" + (error ? " error" : "")} data-alert={"Należy wybrać"}>
            <label> { props.label } { loader && <Loader /> }</label>
            {!props.disableLeftCol &&
                <div className="search-wrapper">
                    <Input  
                        type={ "search" }
                        required={ false }
                        setFunction={ val => setSearchVal(val) }
                        onClickEnter={ () => search() }
                        value={ searchVal }
                        placeholder={"Wyszukaj"}
                        ariaLabel="Wyszukaj po tytule"
                        regExp={ /.*/ }
                        minChar={ 0 } 
                    />
                </div>   
            }
            {props.filter &&
                <div className="select-wrapper">
                    <Select name="category"
                        data={ optionsCategory } 
                        value={ props.selectedCategory }
                        handle={ (val) => setSelectedCategory(val) } 
                        class="btn-primary btn-simple" />
                </div>
            }
            <div className={`box-select ${props.disableLeftCol ? 'big-box' : ''}`}>
                {!props.disableLeftCol &&
                    <div className="col-select" onScroll={() => scrollPages()} ref={scrollRef}>
                        {data && data.length > 0 && data.map((item, index) => (
                            <div className={"row-item " + checkClass(item)} 
                                key={index} 
                                onClick={ () => addSelected(item)} 
                                onKeyDown={(e) => handleKeyDown(e, addSelected, item)} 
                                tabIndex={checkClass(item) ? "-1" : "0"} 
                                role="button" 
                                aria-label={`Wybierz ${(props.label != "Użytkownicy" ? (item.name ? item.name : item.title) : item.name + ' ' + item.surname)}`}>
                                <span>{ (props.label != "Użytkownicy" ? (item.name ? item.name : item.title) : item.name + ' ' + item.surname) }</span>
                            </div>
                        ))}
                    </div>
                }
                <DragDropContext onDragEnd={handleRLDDChange}>
                    <Droppable droppableId="selected-items">
                        {(provided) => (
                            <div className={"col-select selected-items" + ( error ? ' error ' : '')} {...provided.droppableProps} ref={provided.innerRef}>
                            {selected && selected.map((sel, index) => sel && (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                    {(provided) => provided && (
                                        <div className="row-item" 
                                            key={index} 
                                            onClick={ () => !props.disableLeftCol && removeSelected(sel)} {...provided.draggableProps} 
                                            ref={provided.innerRef} {...provided.dragHandleProps} 
                                            onKeyDown={(e) => !props.disableLeftCol && handleKeyDown(e, removeSelected, sel)} 
                                            tabIndex={"0"} 
                                            role="button" 
                                            aria-label={`Odznacz`}>
                                            <span>{ (props.label != "Użytkownicy" ? (sel.title ? sel.title : sel.name) : sel.name + ' ' + sel.surname) }</span>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>   
    );
}

export default SelectItems;
