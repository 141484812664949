import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Editor from '../../../editor/Editor';
import Link from './../../../link/Link';
import Button from './../../../button/Button';
import ColorPicker from './../../../color-picker/ColorPicker';
import Select from './../../../select/Select';
import MediaLoad from '../../../media-load/MediaLoad';
import Input from './../../../input/Input';
import SelectItems from '../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector } from 'react-redux';

let validateNumber = 0;
const BoxReadMore = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                text: '',
                centerText: 'false',
                bgColor: '#FFFFFF',
                withSpan: 'false',
                imageNotFullWidth: 'false',
                image: '',
                links: [
                    {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    },
                ],
                boxesTitle: '',
                boxesIsSmall: 'false',
                boxes: [],
                blackButton: [
                    {
                        url: '',
                        target: "false",
                        text: '',
                        uppercase: 'initial'
                    }
                ],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length <= 1) {
                props.onChange({
                    text: '',
                    centerText: 'false',
                    bgColor: '#FFFFFF',
                    withSpan: 'false',
                    imageNotFullWidth: 'false',
                    image: '',
                    links: [
                        {
                            url: '',
                            target: "false",
                            text: '',
                        },
                    ],
                    boxesTitle: '',
                    boxesIsSmall: 'false',
                    boxes: [],
                    blackButton: [
                        {
                            url: '',
                            target: "false",
                            text: '',
                        }
                    ],
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateFields = (val, index) => {
        let newData = props.data.links;
        newData = newData.map((item, i) => i == index ? item = val : item);
        saveData(newData);
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            links: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.box-read-more-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll(`.box-read-more-${props.index} .box-read-more-content`);
            sections[index].children[1].classList.toggle('active');
        }
    }

    const addItem = () => {
        let newLinks = props.data.links ? props.data.links : [];
        let newData = [...newLinks, {
            url: '',
            target: 'false',
            text: '',
        }]
        saveData(newData);
    }

    const removeItem = (index) => {
        let newData = props.data.links.filter((item, i) => i == index ? false : item);
        saveData(newData);
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data.links);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        saveData(items);
    }
    
    const centerOptions = [
        {
            value: "false",
            text: "Nie centruj"
        },
        {
            value: "true",
            text: "Wycentruj"
        },
    ]

    const withSpanOptions = [
        {
            value: "false",
            text: "no withSpan"
        },
        {
            value: "true",
            text: "withSpan"
        },
    ]

    const imageOptions = [
        {
            value: "false",
            text: "na całą szerokość"
        },
        {
            value: "true",
            text: "na ograniczoną szerokość"
        },
    ]

    const boxesOptions = [
        {
            value: "false",
            text: "Duży box"
        },
        {
            value: "true",
            text: "Mały box",
        }
    ]
    return (
        <div className={error ? `section-wrapper box-read-more error-section box-read-more-${props.index}` : `section-wrapper box-read-more box-read-more-${props.index}`}>
                <div className="row-section">
                    <ColorPicker value={props.data.bgColor} setFunction={(val) => updateValue(val, 'bgColor')} label="Kolor tła" colors={ ["#E1E1E1", "#FFFFFF", "#349F5A"] } />
                </div>
                <div className="row-section border">
                    <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Treść" />
                    <div className="select-options">
                        <Select name="center"
                            data={ centerOptions } 
                            value={ props.data.centerText }
                            label={"Wycentrować tekst?"}
                            handle={ (val) => updateValue(val, 'centerText') } 
                        />
                    </div>
                </div>
                <div className="row-section border">
                    <MediaLoad setFunction={ val => updateValue(val, 'image') } value={props.data.image} label="Zdjęcie" withRemove />
                    <div className="select-options">
                        <Select name="width-image"
                            data={ imageOptions } 
                            value={ props.data.imageNotFullWidth }
                            label={"Zdjęcie ma być"}
                            handle={ (val) => updateValue(val, 'imageNotFullWidth') } 
                        />
                    </div>
                </div>
                <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.links && props.data.links.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="box-read-more-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Przycisk {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <div className="row-section">
                                                    <Link value={item} setFunction={ val => updateFields(val, index)} forceValidate={ forceValidate } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-item-in-section">
                <Button title="Dodaj link" setFunction={() => addItem()} />   
            </div>
            <div className="row-section">
                <div className="select-options">
                    <Select name="center"
                        data={ withSpanOptions } 
                        value={ props.data.withSpan }
                        // label={"withSpan ????????"}
                        handle={ (val) => updateValue(val, 'withSpan') } 
                    />
                </div>
            </div>
            <div className="row-section border">
                <Input  required={ false }
                    setFunction={ val => updateValue(val, 'boxesTitle') }
                    value={props.data.boxesTitle}
                    label={ 'Nagłówek dla boxów' }
                    regExp={ /.*/ }
                    minChar={ 3 } 
                    forceValidate={ forceValidate } 
                />
                <div className="select-options">
                    <Select name="boxes"
                        data={ boxesOptions } 
                        value={ props.data.boxesIsSmall }
                        label={"Wielkość boxa"}
                        handle={ (val) => updateValue(val, 'boxesIsSmall') } 
                    />
                </div>
                <div className="select-boxes">
                    <SelectItems
                        label="Kolekcje"
                        value={ props.data.boxes }
                        handle={ (val) => updateValue(val, 'boxes') } 
                        langID={lang}
                        postType="collections"
                        column="xs-12" />
                </div>
                <div className="row-section">
                    <Link value={props.data.blackButton} setFunction={ val => updateValue(val, 'blackButton')} label="Czarny przycisk" />
                </div>
            </div>
        </div>
    );
}

export default BoxReadMore;