import React, { useState, useEffect } from 'react'
import Textarea from './../../../input/Textarea';
import Input from './../../../input/Input';
import Select from './../../../select/Select';
import EditorComponent from './../../../editor/Editor';

let validateNumber = 0;
const ProductsFilter = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                header: '',
                text: '',
                postType: 'products',
                pagination: '6',
                btnText: 'Load more',
                filter: false,
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    header: '',
                    text: '',
                    postType: 'products',
                    pagination: '6',
                    btnText: 'Load more',
                    filter: false,
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.products-filter-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const options = [
        {
            value: "false",
            text: "Wyłączone"
        },
        {
            value: "true",
            text: "Włączone"
        },
    ]

    const postTypesOptions = [
        {
            value: "products",
            text: "Produkty"
        },
        {
            value: "pages",
            text: "Strony"
        },
    ]

    return (
        <div className={error ? `section-wrapper products-filter error-section products-filter-${props.index}` : `section-wrapper products-filter products-filter-${props.index}`}>
            <div className="row-section">
                <EditorComponent required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Tekst" />
            </div>
            <div className="row-section">
                <Input  required={ false }
                    setFunction={ val => updateValue(val, 'header') }
                    value={props.data.header}
                    label={ 'Nagłówek' }
                    regExp={ /.*/ }
                    minChar={ 3 } 
                    forceValidate={ forceValidate } 
                />
            </div>
            <div className="row-section">
                <Select name="status"
                    data={ options } 
                    label="Filtrowanie"
                    value={ String(props.data.filter) }
                    handle={ (val) => updateValue(val, 'filter') } 
                    class="btn-primary btn-simple" />
            </div>
            {/* <div className="row-section">
                <Select name="postType"
                    data={ postTypesOptions } 
                    label="Typ stron"
                    value={ String(props.data.postType) }
                    handle={ (val) => updateValue(val, 'postType') } 
                    class="btn-primary btn-simple" />
            </div> */}
            <div className="row-section">
                <Input  required={ true }
                    setFunction={ val => updateValue(val, 'pagination') }
                    value={props.data.pagination}
                    label={ 'Paginacja' }
                    regExp={ /^[0-9]+$/ }
                    minChar={ 1 } 
                    forceValidate={ forceValidate } 
                />
            </div>
            <div className="row-section">
                <Input  required={ true }
                    setFunction={ val => updateValue(val, 'btnText') }
                    value={props.data.btnText}
                    label={ 'Treść przycisku doładowującego' }
                    regExp={ /.*/ }
                    minChar={ 3 } 
                    forceValidate={ forceValidate } 
                />
            </div>
        </div>
    );
}

export default ProductsFilter;