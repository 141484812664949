//change data section before save
const filterSectionsData = (sections) => {
    let copySections = sections;
    copySections = copySections.map((section, i) => {
        let newData = section.data;
        section.component = null;
        section.order = i;
        section.image = null;
        if(section.section == "banner_section") {
            newData.image = newData.image?.id;
        }else if(section.section == "box_image_read_more_section") {
            newData.bgImage = newData.bgImage?.id;
        }else if(section.section == "box_text_links_section") {
            newData.logo = newData.logo.id;
            let pages = newData.linksPages;
            pages = pages && pages.map(item => {
                item = item?.id;
                return item;
            })
            newData.linksPages = pages;
        }else if(section.section == "box_header_links_section") {
            let pages = newData.linksPages;
            pages = pages && pages.map(item => {
                item = item?.id;
                return item;
            })
            let images = newData.images;
            images = images && images.map(item => {
                item.image = item.image?.id;
                return item;
            })
            newData.images = images;
            newData.linksPages = pages;
        }else if(section.section == "box_read_more_section") {
            if(newData.image) {
                newData.image = newData.image?.id;
            }
        }else if(section.section == "video_section") {
            newData.poster = newData.poster?.id;
        }else if(section.section == "banner_title_section") {
            newData.image = newData.image?.id;
        }else if(section.section == "logos_inline_section") {
            let logos = newData.logos;
            logos = logos && logos.map(item => {
                item = item?.id;
                return item;
            })
            newData.logos = logos;
        }else if(section.section == "gallery_section" || section.section == "patterns_section") {
            let items = newData.items;
            items = items && items.map(item => {
                item.image = item.image?.id;
                return item;
            })
            newData.items = items;
        }else if(section.section == "files_download_section") {
                let files = newData.files;
                files = files && files.map(attr => {
                    attr.file = attr.file?.id;
                    attr.icon = attr.icon?.id;
                    return attr;
                })
                newData.files = files;
        }else if(section.section == "environment_boxes_section") {
            let boxes = newData.boxes;
            boxes = boxes && boxes.map(box => {
                box.img = box.img?.id;
                return box;
            })
            newData.boxes = boxes;
        }else if(section.section == "boxes_image_editor_section") {
            let boxes = newData.boxes;
            boxes = boxes && boxes.map(box => {
                box.img = box.img?.id;
                box.logo = box.logo?.id;
                return box;
            })
            newData.boxes = boxes;
        }else if(section.section == "image_description_section") {
            newData.image = newData.image?.id;
        }else if(section.section == "realization_section") {
            let gallery = newData.gallery;
            gallery = gallery && gallery.map(item => {
                item.image = item.image?.id;
                return item;
            })
            newData.gallery = gallery;
        }else if(section.section == "colors_list_section") {
            let colors = newData.colors;
            // colors = colors && colors.map(item => {
            //     item = item?.id;
            //     return item;
            // })
            newData.colors = colors; 
        }else if(section.section == "brands_section") {
            let brands = newData.brands;
            brands = brands && brands.map(item => {
                item = item?.id;
                return item;
            })
            newData.brands = brands;
        }else if(section.section == "images_desc_section" || section.section == "icons_desc_section") {
            let items = newData.items;
            items = items && items.map(item => {
                item.img = item.img?.id;
                return item;
            })
            newData.items = items;
        }else if(section.section == "table_image_section") {
            newData.table = newData.table.id;
        }else if(section.section == "collections_section") {
            let collections = newData.collections;
            collections = collections && collections.map(item => {
                item = item?.id;
                return item;
            })
            newData.collections = collections;
        }else if(section.section == "accessories_section") {
            let accesories = newData.accesories;
            accesories = accesories && accesories.map(item => {
                item = item?.id;
                return item;
            })
            newData.accesories = accesories;
        }else if(section.section == "marketing_support_list_section") {
            let items = newData.items;
            items = items && items.map(item => {
                item = item?.id;
                return item;
            })
            newData.items = items;
        }else if(section.section == "technical_desc_section") {
            let items = newData.items;
            items = items && items.map(item => {
                item = item?.id;
                return item;
            })
            newData.items = items;
        }else if(section.section == "pages_list_section") {
            let pages = newData.linksPages;
            pages = pages && pages.map(item => {
                item = item?.id;
                return item;
            })
            newData.linksPages = pages;
        }
        section.data = newData;
        return section;
    });
    return copySections;
}

export default filterSectionsData;