import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import actualDate from './../../../services/actualDate';
import Input from './../../../components/input/Input';
import Button from './../../../components/button/Button';
import { connect } from 'react-redux';
import { setNotificationAlert } from './../../actions/index';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            forceValidate: false,
            blocked: false,
        }
    }

    componentDidMount() {
        this.registerAlert();
    }

    registerAlert() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let register = params.get('register');
        if(register == 'success') {
            alert("Zostałeś zarejestrowany. Teraz możesz się zalogować.");
        }
    }

    validate = async(e) => {
        await this.setState({ forceValidate: true });
        await this.wait(100);
        await this.setState({ forceValidate: false });
        let errors = await this.checkErrors();
        if(!errors) {
            this.actionLogin();
        }
    }

    wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.form-login .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    async actionLogin() {
        if(!this.state.blocked) {
            this.setState({ blocked: true })
            axios({
                method: 'post',
                url: `${ApiUrl()}/login`,
                data: {
                    password: this.state.password,
                    email: this.state.email,
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status === 1) {
                    if(res.acc_type == 1) {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        localStorage.removeItem('sessionDate');
                        this.props.setNotificationAlert("<p>Błędny login lub hasło</p>")
                    }else{
                        localStorage.setItem('token', res.token);
                        localStorage.setItem('userId', res.userId);
                        localStorage.setItem('sessionDate', actualDate());
                        window.location.reload();
                    }
                }else{
                    localStorage.removeItem('token');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('sessionDate');
                    this.props.setNotificationAlert("<p>Błędny login lub hasło</p>")
                }
            }).catch(e => {
                // alert("Błędny login lub hasło.");
                this.props.setNotificationAlert("<p>Coś poszło nie tak, prosimy spróbować później</p>")
            })
            this.setState({ blocked: false })
        }
    }
    
    render() {
        return (
            <div className="form-login">
                <h1>Zaloguj się</h1>
                <form>
                    <Input  
                        required={ true }
                        setFunction={ val => this.setState({ email: val }) }
                        value={ this.state.email }
                        placeholder={ 'Wpisz adres email' }
                        regExp={ /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }
                        minChar={ 6 } 
                        forceValidate={ this.state.forceValidate }
                        onClickEnter={ () => this.validate() } 
                    />
                    <Input  
                        required={ true }
                        setFunction={ val => this.setState({ password: val }) }
                        value={ this.state.password }
                        placeholder={ 'Wpisz hasło' }
                        regExp={ /.*/ }
                        minChar={ 1 } 
                        type="password"
                        forceValidate={ this.state.forceValidate } 
                        mode="password"
                        onClickEnter={ () => this.validate() }
                    />
                    <Button title="Zaloguj" setFunction={() => this.validate()} />
                </form>
                <div className="navigation">
                    {/* <small>Nie masz konta? <Link to="/login/new-user">załóż konto</Link></small> */}
                    <small>Zapomniałeś hasła? <Link tabIndex="0" role="button" to="/login/send-reset-password">zresetuj hasło</Link></small>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setNotificationAlert
}

const mapStateToProps = (state) => {
    return { }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)