import React, { useState,useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Header from './../../components/layout/header/Header';
import Navbar from './../../components/layout/navbar/Navbar';
import Welcome from './welcome/Welcome';
import Pages from './pages/Pages';
import Chat from './chat/Chat';
import Users from './users/Users';
import MediaLibrary from './media-library/MediaLibrary';
import Translates from './translates/Translates';
import Questions from './questions/Questions';
import Clinics from './clinics/Clinics';
import AutotestsAnswers from './autotests-answers/AutotestsAnswers';
import Menus from './menus/Menus';
import Settings from './settings/Settings';
import NewUser from './users/new-user/NewUser';
import EditUser from './users/edit-user/EditUser';
import NewQuestion from './questions/new-question/NewQuestion';
import Question from './questions/question/Question';
import Translate from './translates/translate/Translate';
import NewTranslate from './translates/new-translate/NewTranslate';
import NewClinic from './clinics/new-clinic/NewClinic';
import Clinic from './clinics/clinic/Clinic';
import Page from './pages/page/Page';
import NewPage from './pages/new-page/NewPage';
import ProductsGroup from './pages/products-group/ProductsGroup';
import ExcelMarketing from './pages/marketing/Excel';
import Bans from './chat/bans/Bans';
import Categories from './categories/Categories'; 
import ApiUrl from '../../services/ApiUrl';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { PermissionsRoute } from '../../components/custom-routes/permissions.route';

let userData = [];
const DashboardView = (props) => {
    
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await sessionUser();
                await getUser();
                await getLanguages();
                await getMenus();
                await getCategories();
                await getCountData();
                await setLoad(true);
            })()
        }
    },[load]);

    const sessionUser = async () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (!currentUser) {
            props.history.push({ pathname: '/login' });
        }
    }

    const getUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/me`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }

        }).then(response => {
            let res = JSON.parse(response.request.response);
            userData = res;
            dispatch({ type: 'USER_DATA', value: userData })
            getPermissions(res);
        });
        // ,
        // reponse => {
        //     props.history.push({ pathname: '/login' });
        // });
    }

    const getMenus = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getMenus`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'MENUS', value: res.data })
        })
    }

    const getLanguages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getLanguages`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'LANGUAGES', value: res.data })
        })
    }

    const getCategories = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/getCategories`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'CATEGORIES', value: res.data })
        })
    }

    const getCountData = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/countData`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            dispatch({ type: 'COUNT_DATA', value: res })
        });
    }
    
    const getPermissions = (data) => {
        let permissions = data.account_type.permissions
        let resultPermissions = [];
        if(permissions) {
            permissions.map(access => {
                if(access.action == "page-users") {
                    resultPermissions = [...resultPermissions, "page-users"];
                }else if(access.action == "page-library") {
                    resultPermissions = [...resultPermissions, "page-library"];
                }else if(access.action == "page-pages") {
                    resultPermissions = [...resultPermissions, "page-pages"];
                }else if(access.action == "page-menus") {
                    resultPermissions = [...resultPermissions, "page-menus"];
                }else if(access.action == "page-translates") {
                    resultPermissions = [...resultPermissions, "page-translates"];
                }else if(access.action == "page-dashboard") {
                    resultPermissions = [...resultPermissions, "page-dashboard"];
                }else if(access.action == "page-articles") {
                    resultPermissions = [...resultPermissions, "page-articles"];
                }else if(access.action == "page-settings") {
                    resultPermissions = [...resultPermissions, "page-settings"];
                }else if(access.action == "page-brands") {
                    resultPermissions = [...resultPermissions, "page-brands"];
                }else if(access.action == "page-products") {
                    resultPermissions = [...resultPermissions, "page-products"];
                }else if(access.action == "page-colors") {
                    resultPermissions = [...resultPermissions, "page-colors"];
                }else if(access.action == "page-collections") {
                    resultPermissions = [...resultPermissions, "page-collections"];
                }else if(access.action == "page-technical-description") {
                    resultPermissions = [...resultPermissions, "page-technical-description"];
                }else if(access.action == "page-marketing-support") {
                    resultPermissions = [...resultPermissions, "page-marketing-support"];
                }else if(access.action == "page-human-touch") {
                    resultPermissions = [...resultPermissions, "page-human-touch"];
                }else if(access.action == "page-realizations") {
                    resultPermissions = [...resultPermissions, "page-realizations"];
                }else if(access.action == "page-accessories") {
                    resultPermissions = [...resultPermissions, "page-accessories"];
                }else if(access.action == "page-categories") {
                    resultPermissions = [...resultPermissions, "page-categories"];
                }
            })
        }
        dispatch({ type: 'USER_PERMISSIONS', value: resultPermissions })
    }
    return (
        <>
            <div className="skip-link">
                <a href="#content-cms">Przejdź do głównej zawartości strony</a>
            </div>
            <Navbar location={props.location} />
            <main>
                <Header props={props} user={ userData } />
                <div className="content-cms" id="content-cms">
                    <Switch>
                        <PermissionsRoute path={`/dashboard`} exact component={ Welcome } props={ props } type="page-dashboard" />
                        <PermissionsRoute path={`/dashboard/media-library`} exact component={ MediaLibrary } props={ props } type="page-library" />
                        <PermissionsRoute path={`/dashboard/users`} exact component={ Users } props={ props } type="page-users" />
                        <PermissionsRoute path={`/dashboard/translates`} exact component={ Translates } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/menus`} exact component={ Menus } props={ props } type="page-menus" />
                        <PermissionsRoute path={`/dashboard/settings`} component={ Settings } props={ props } type="page-settings" />
                        <PermissionsRoute path={`/dashboard/users/new`} component={ NewUser } props={ props } type="page-users" />
                        <PermissionsRoute path={`/dashboard/translates/page/:id`} exact component={ Translate } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/translates/new`} component={ NewTranslate } props={ props } type="page-translates" />
                        <PermissionsRoute path={`/dashboard/pages`} exact component={ Pages } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/pages/new`} component={ NewPage } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/pages/page/:id`} exact component={ Page } props={ props } type="page-pages" />
                        <PermissionsRoute path={`/dashboard/articles`} exact component={ Pages } props={ props } type="page-articles" />
                        <PermissionsRoute path={`/dashboard/articles/new`} component={ NewPage } props={ props } type="page-articles" />
                        <PermissionsRoute path={`/dashboard/articles/page/:id`} exact component={ Page } props={ props } type="page-articles" />
                        <PermissionsRoute path={`/dashboard/brands`} exact component={ Pages } props={ props } type="page-brands" />
                        <PermissionsRoute path={`/dashboard/brands/new`} component={ NewPage } props={ props } type="page-brands" />
                        <PermissionsRoute path={`/dashboard/brands/page/:id`} exact component={ Page } props={ props } type="page-brands" />
                        <PermissionsRoute path={`/dashboard/products`} exact component={ Pages } props={ props } type="page-products" />
                        <PermissionsRoute path={`/dashboard/products/new`} component={ NewPage } props={ props } type="page-products" />
                        <PermissionsRoute path={`/dashboard/products/page/:id`} exact component={ Page } props={ props } type="page-products" />
                        <PermissionsRoute path={`/dashboard/products/group/:id`} exact component={ ProductsGroup } props={ props } type="page-products" />
                        <PermissionsRoute path={`/dashboard/colors`} exact component={ Pages } props={ props } type="page-colors" />
                        <PermissionsRoute path={`/dashboard/colors/new`} component={ NewPage } props={ props } type="page-colors" />
                        <PermissionsRoute path={`/dashboard/colors/page/:id`} exact component={ Page } props={ props } type="page-colors" />
                        <PermissionsRoute path={`/dashboard/collections`} exact component={ Pages } props={ props } type="page-collections" />
                        <PermissionsRoute path={`/dashboard/collections/new`} component={ NewPage } props={ props } type="page-collections" />
                        <PermissionsRoute path={`/dashboard/collections/page/:id`} exact component={ Page } props={ props } type="page-collections" />
                        {/* technical description */}
                        <PermissionsRoute path={`/dashboard/technical-description`} exact component={ Pages } props={ props } type="page-technical-description" />
                        <PermissionsRoute path={`/dashboard/technical-description/new`} component={ NewPage } props={ props } type="page-technical-description" />
                        <PermissionsRoute path={`/dashboard/technical-description/page/:id`} exact component={ Page } props={ props } type="page-technical-description" />
                        {/* marketing support */}
                        <PermissionsRoute path={`/dashboard/marketing-support`} exact component={ Pages } props={ props } type="page-marketing-support" />
                        <PermissionsRoute path={`/dashboard/marketing-support/new`} component={ NewPage } props={ props } type="page-marketing-support" />
                        <PermissionsRoute path={`/dashboard/marketing-support/page/:id`} exact component={ Page } props={ props } type="page-marketing-support" />
                        <PermissionsRoute path={`/dashboard/marketing-support/excel`} component={ ExcelMarketing } props={ props } type="page-marketing-support" />
                        {/* human touch */}
                        <PermissionsRoute path={`/dashboard/human-touch`} exact component={ Pages } props={ props } type="page-human-touch" />
                        <PermissionsRoute path={`/dashboard/human-touch/new`} component={ NewPage } props={ props } type="page-human-touch" />
                        <PermissionsRoute path={`/dashboard/human-touch/page/:id`} exact component={ Page } props={ props } type="page-human-touch" />
                        {/* realizations */}
                        <PermissionsRoute path={`/dashboard/realizations`} exact component={ Pages } props={ props } type="page-realizations" />
                        <PermissionsRoute path={`/dashboard/realizations/new`} component={ NewPage } props={ props } type="page-realizations" />
                        <PermissionsRoute path={`/dashboard/realizations/page/:id`} exact component={ Page } props={ props } type="page-realizations" />
                        {/* accessories */}
                        <PermissionsRoute path={`/dashboard/accessories`} exact component={ Pages } props={ props } type="page-accessories" />
                        <PermissionsRoute path={`/dashboard/accessories/new`} component={ NewPage } props={ props } type="page-accessories" />
                        <PermissionsRoute path={`/dashboard/accessories/page/:id`} exact component={ Page } props={ props } type="page-accessories" />
                        
                        <PermissionsRoute path={`/dashboard/categories`} component={ Categories } props={ props } type="page-categories" />
                        <PermissionsRoute path={`/dashboard/users/user/:id`} component={ EditUser } props={ props } type="page-users" />
                        <PermissionsRoute path={`/dashboard/profil`} exact component={ EditUser } props={ props } type="page-dashboard" />
                    </Switch>
                </div>
            </main>
        </>
    );
}

export default DashboardView;