import React, { useState, useEffect } from 'react'
import MediaLoad from './../../components/media-load/MediaLoad';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Gallery = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);

    useEffect(() => {
        if(props.validate) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.value);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.setFunction(items);
    }

    const addItem = (item) => {
        let newData = [...props.value, {
            image: item,
        }]
        props.setFunction(newData);
    }

    const removeItem = (id) => {
        let newData = props.value.filter((item, i) => (item.image?.id === id || !item.image) ? false : item);
        props.setFunction(newData);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.gallery-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const handleKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter' || e.key == 'Escape') {
            functionName(functionValue, functionValueSecond)
        }
    }

    return (
        <div className={error ? `section-wrapper gallery error-section` : `section-wrapper gallery`}>

            <div className="row-section">
                <MediaLoad forceValidate={ props.forceValidate } changeRDLO={ (items) => props.setFunction(items) } setFunction={ val => addItem(val)} value={props.value} only="image" type="gallery" items={props.value} removeItem={(id) => removeItem(id)} label="Zdjęcia" />
            </div>
        </div>
    );
}

export default Gallery;