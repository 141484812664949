import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Input from './../../../../components/input/Input';
import Textarea from './../../../../components/input/Textarea';
import axios from 'axios';
import ApiUrl from './../../../../services/ApiUrl';
import Sections from './../../../../components/sections/Sections';
import LanguagesSettings from './../../../../components/boxes/LanguagesSettings';
import Tags from './../../../../components/boxes/Tags';
import { useSelector, useDispatch } from 'react-redux'
import Categories from './../../../../components/boxes/Categories';
import DateTime from './../../../../components/boxes/DateTime';
import getFormatDateTime from './../../../../services/getFormatDateTime';
import filterSectionsData from './../../../../services/FilterSectionsData';
import DateTimeRangerPicker from '../../../../components/boxes/DateTimeRangerPicker';
import ImagePost from './../../../../components/boxes/ImagePost';
import Select from './../../../../components/select/Select';
import StatusPage from './../../../../components/boxes/StatusPage';
import WebUrl from './../../../../services/WebUrl';
import PageTitle from './../../../../components/page-title/PageTitle';
import Button from './../../../../components/button/Button';
import readTextNvda from './../../../../services/readTextNvda';
import SaveIcon from './../../../../svg-components/SaveIcon';
import Editor from './../../../../components/editor/Editor';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import SelectItems from './../../../../components/select-items/SelectItems';
import Gallery from './../../../../components/gallery/Gallery';
import IconDesc from './../../../../components/icon-desc/IconDesc';
import filterData from './../../../../services/filterData';
import FilesDownload from './../../../../components/files-download/FilesDownload';

const Page = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ blockedBtn, setBlockedBtn ] = useState(true);
    let [ title, setTitle ] = useState('');
    let [ sneakpeak, setSneakpeak ] = useState('');
    let [ disableTopDate, setDisableTopDate ] = useState(false);
    let [ important, setImportant ] = useState(false);
    let [ errorTitle, setErrorTitle ] = useState(false);
    let [ status, setStatus ] = useState(false);
    let [ errorSneakpeak, setErrorSneakpeak ] = useState(false);
    let [ sections, setSections ] = useState('empty')
    let [ resetLoad, setResetLoad ] = useState(0);
    let [ validateSections, setValidateSections ] = useState(0);
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let postType = typeof window !== "undefined" && (window.location.pathname).split("/");postType = postType[2]
    let [ resultPage, setResultPage ] = useState(false);
    let categories = useSelector(state => state.categories);
    let lang = useSelector(state => state.lang);
    let languages = useSelector(state => state.languages);
    let [ date, setDate ] = useState();
    let [ removeSections, setRemoveSections ] = useState([]);
    let [ dateRange, setDateRange ] = useState([]) 
    let [ previewImage, setPreviewImage ] = useState(false);
    let [ categoriesArray, setCategoriesArray ] = useState([]);
    let [ seoDescription, setSeoDescription ] = useState('');
    let [ pagePreviewUrl, setPagePreviewUrl ] = useState(false);
    let [ version, setVersion ] = useState(false);
    let [ allVersions, setAllVersions ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ lastClickedRadioCategory, setLastClickedRadioCategory ] = useState(false); 
    let [ resetVersion, setResetVersion ] = useState(false);
    let [ dimensions, setDimensions ] = useState('');
    let [ line, setLine] = useState("");
    let [ width, setWidth ] = useState("");
    let [ height, setHeight ] = useState("");
    let [ length, setLength ] = useState("");
    let [ depth, setDepth ] = useState("");
    let [ coloursTechnicalDesc, setColoursTechnicalDesc ] = useState([]);
    let [ technicalDescription, setTechnicalDescription ] = useState("");
    let [ collectionDesc, setCollectionDesc ] = useState("");
    let [ TD, setTD ] = useState(false); 
    let [ brandsList, setBrandsList ] = useState(false);
    let [ chooseTD, setChooseTD ] = useState([]);
    let [ chooseBrands, setChooseBrands ] = useState([]);
    let [ indexProduct, setIndexProduct ] = useState(false);
    let [ priceNetto, setPriceNetto ] = useState("");
    let [ langsProduct, setLangsProduct ] = useState("");
    let [ city, setCity ] = useState("");
    let [ galleryRealization, setGalleryRealization ] = useState([]);
    let [ sneakpeakImage, setSneakpeakImage ] = useState(false);
    let [ smallHeader, setSmallHeader ] = useState("");
    let [ threeDIcon, setThreeDIcon ] = useState("false");
    let [ iconDescData, setIconDescData ] = useState([]);
    let [ marketingDescription, setMarketingDescription ] = useState("");
    let [ marketingDescriptionAfterPrice, setMarketingDescriptionAfterPrice ] = useState("");
    let [ galleryMT, setGalleryMT ] = useState([]);
    let [ products, setProducts ] = useState([]);
    let [ colorOfProducts, setColorOfProducts ] = useState([]);
    let [ url, setUrl ] = useState("");
    let [ colors, setColors ] = useState([]);
    let [ chooseColors, setChooseColors ] = useState([])
    let [ filesDownload, setFilesDownload ] = useState([{
            file: '',
            category: '',
        }]);
    let [ ctItems, setCtItems ] = useState(0);
    let [ unit, setUnit ] = useState("");
    let [ info, setInfo ] = useState("");
    let [ accessoriesValue, setAccessoriesValue ] = useState(false); //cat group 

    useEffect(() => {
        if(load) {
            changeRelationPage();
        }
    }, [lang])

    useEffect(() => {
        if(!load) {
            getPage();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load) {
            getPage();
            setLoad(false);
        }
    }, [pageID])

    useEffect(() => {
        if(load) {
            validateInputs();
        }
    }, [validateSections])

    const changeRelationPage = () => {
        if(resultPage && resultPage.relations.length > 0) {
            props.history.push(`/dashboard/${postType}/page/${resultPage.relations[0].data.id}`)
        }else{
            props.history.push(`/dashboard/${postType}`)
        }
    }

    const getPage = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getPageByID`,
            data: {
                token: localStorage.getItem('token'),
                pageID: pageID
            }
        }).then(response => {
            let res = JSON.parse(response.request.response).data[0];
            setTitle(res.title);
            setSneakpeak(res.sneak_peak);
            setResultPage(res);
            setDisableTopDate(String(res.disable_top));
            setImportant(String(res.important));
            setDate(new Date(res.created_at));
            setStatus(res.status);
            setSeoDescription(res.seo_description);
            setDimensions(res.dimensions);
            setLine(res.line);
            setWidth(res.width);
            setHeight(res.height);
            setDepth(res.depth);
            setPriceNetto(res.priceNetto);
            setLangsProduct(res.langsProduct);
            setIndexProduct(res.indexProduct);
            setTechnicalDescription(res.technicalDescription);
            setColorOfProducts(res.colorOfProducts);
            createPreviewUrl(res);
            resetVersionPage();
            if(res.coloursTechnicalDesc) {
                setColoursTechnicalDesc(res.coloursTechnicalDesc);
            }
            if(res.date_range_from && (res.date_range_from).length > 0 && res.date_range_to && (res.date_range_to).length > 0) {
                setDateRange([new Date(res.date_range_from), new Date(res.date_range_to)]);
            }
            if(res.preview_image) {
                setPreviewImage(res.preview_image);
            }
            if(res.versions) {
                getVersions(res.versions);
            }
            if(res.collectionDesc) {
                setCollectionDesc(res.collectionDesc);
            }
            if(res.chooseTD) {
                setChooseTD(res.chooseTD);
            }
            if(res.chooseBrands) {
                setChooseBrands(res.chooseBrands);
            }
            if(res.city) {
                setCity(res.city);
            }
            if(res.galleryRealization) {
                setGalleryRealization(res.galleryRealization);
            }
            if(res.sneak_peak_image) {
                setSneakpeakImage(res.sneak_peak_image);
            }
            if(res.smallHeader) {
                setSmallHeader(res.smallHeader);
            }
            if(res.threeDIcon) {
                setThreeDIcon(res.threeDIcon);
            }
            if(res.iconDescData) {
                setIconDescData(res.iconDescData);
            }
            if(res.marketingDescription) {
                setMarketingDescription(res.marketingDescription);
            }
            if(res.filesDownload) {
                setFilesDownload(res.filesDownload);
            }
            if(res.marketingDescriptionAfterPrice) {
                setMarketingDescriptionAfterPrice(res.marketingDescriptionAfterPrice);
            }
            if(res.galleryMT) {
                setGalleryMT(res.galleryMT);
            }
            if(res.url) {
                setUrl(res.url);
            }
            if(res.chooseColors) {
                setChooseColors(res.chooseColors);
            }
            if(res.unit) {
                setUnit(res.unit);
            }
            if(res.ctItems) {
                setCtItems(res.ctItems);
            }
            if(res.length) {
                setLength(res.length);
            }
            if(res.info) {
                setInfo(res.info);
            }
            if(res.accessoriesValue) {
                setAccessoriesValue(res.accessoriesValue);
            }
            if(res.sections && (res.sections).length > 0) {
                let dataSections = res.sections;
                if(dataSections && dataSections.length > 0) {
                    setSections(dataSections)
                }else{
                    setSections([])
                }
            }else{
                setSections([])
            }
            setResetLoad(generateRandomNumber(1, 99999999));
            setBlockedBtn(false);
        })
    }

    const resetVersionPage = async () => {
        await setVersion(false);
        await setResetVersion(true);
        await wait(300);
        await setResetVersion(false);
    }

    const getVersions = (versions) => {
        let result = [{
            value: false,
            text: "Nic nie wybrano"
        }];
        versions.map( version => {
                result = [...result, {
                    value: version.id,
                    text: version.created_at,
                    key: version.preview_key,
                    user: version.user.login
                }]
        })
        setAllVersions(result);
    }

    const createPreviewUrl = (data) => {
        let url = ''
        let lang = (data.language.shortcode).toLowerCase(); 
        let slug = data.slug;
        let postType = data.post_type.slug;
        if(lang == "pl") {
            lang = "";
        }else{
            lang = "/" + lang
        }
        if(postType == "pages") {
            if(slug == "strona-glowna" || slug == "home" || slug == "homepage" || slug == "strona-gwna") {
                slug = "";
                // lang = "";
            }
            if(data.status == "published") {
                url = `${WebUrl()}${lang}/${slug}`
            }else{
                url = `${WebUrl()}${lang}/${slug}?key=${data.preview_key}`
            }
        }else if(postType == "products") {
            if(data.status == "published") {
                url = `${WebUrl()}${lang}/${slug}`
            }else{
                url = `${WebUrl()}${lang}/${slug}?key=${data.preview_key}`
            }
        }else{
            // if(data.status == "published") {
            //     url = `${WebUrl()}${lang}/${data.category[0].data.slug}/${data.slug}`
            // }else{
            //     url = `${WebUrl()}${lang}/${data.category[0].data.slug}/${data.slug}?key=${data.preview_key}`
            // }
            if(data.status == "published") {
                url = `${WebUrl()}${lang}/${postType}/${slug}`
            }else{
                url = `${WebUrl()}${lang}/${postType}/${slug}?key=${data.preview_key}`
            }
        }
        setPagePreviewUrl(url)
    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    let validateForm = async() => {
        await setValidateSections(generateRandomNumber(1, 99999999)) // run validate sections
        await setForceValidate(true);
        await wait(300);
        await setForceValidate(false);
        let errors = await checkErrors();
        if ( !errors ) {
            await save();
        } 
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let validateInputs = () => {
        if(title.length == 0) {
            setErrorTitle(1);
        }else{
            setErrorTitle(false);
        }
        if(postType !== "pages") {
            if(!sneakpeak || sneakpeak.length == 0) {
                setErrorSneakpeak(1);  
            }else{
                setErrorSneakpeak(false);
            }
        }
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.sections-component .error');
        let elementsCard = document.querySelectorAll('.page-container .error');

        if( elements.length > 0 ) {
            elements[0].closest('.content').classList.add("active");
            readTextNvda("sections");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else if(elementsCard.length > 0) {
            readTextNvda("page");
            let topElement = elementsCard[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = async () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            let sendSections = false;
            if(!version) {
                sendSections = filterSectionsData(sections);
                if(filesDownload && postType == "marketing-support") {
                    // filesDownload = filterData(filesDownload, "filesDownload");
                    filesDownload = false;
                    galleryMT = filterData(galleryMT, "galleryMT");
                }else{
                    filesDownload = false;
                    galleryMT = false;
                }
                if(colorOfProducts && postType == "colors") {
                    colorOfProducts = filterData(colorOfProducts, "colorOfProducts");
                    iconDescData = filterData(iconDescData, "iconDescData");
                }else {
                    colorOfProducts = false;
                    iconDescData = false;
                }
            }
            axios({
                method: 'post',
                url: `${ApiUrl()}/savePage`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                data: {
                    token: localStorage.getItem('token'),
                    title: title,
                    sneakpeak: sneakpeak,
                    sections: sendSections,
                    removeSections: removeSections,
                    userID: localStorage.getItem('userId'),
                    pageID: pageID,
                    postType: postType,
                    categoriesItems: categoriesArray,
                    created_at: date ? getFormatDateTime(date) : null,
                    dateRange: dateRange,
                    previewImage: previewImage ? previewImage.id : false,
                    disableTop: disableTopDate,
                    important: important,
                    status: status,
                    seoDescription: seoDescription,
                    version: version,
                    dimensions, dimensions,
                    line: line,
                    width: width,
                    height: height,
                    depth: depth,
                    length: length,
                    coloursTechnicalDesc: coloursTechnicalDesc,
                    technicalDescription: technicalDescription,
                    collectionDesc: collectionDesc,
                    chooseTD: chooseTD,
                    chooseBrands: chooseBrands,
                    indexProduct: indexProduct,
                    priceNetto: priceNetto,
                    langsProduct: langsProduct,
                    city: city,
                    galleryRealization: galleryRealization,
                    sneakpeakImage: (sneakpeakImage ? sneakpeakImage.id : false),
                    smallHeader: smallHeader,
                    threeDIcon: threeDIcon,
                    iconDescData, iconDescData,
                    marketingDescription: marketingDescription,
                    filesDownload: filesDownload,
                    marketingDescriptionAfterPrice: marketingDescriptionAfterPrice,
                    galleryMT: galleryMT,
                    colorOfProducts: colorOfProducts,
                    chooseColors: chooseColors,
                    url: url,
                    unit: unit,
                    ctItems: ctItems,
                    info: info,
                    accessoriesValue: accessoriesValue,
                    edit: true
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    // setBlockedBtn(false);
                    props.history.push(`/dashboard/${postType}/page/${pageID}`)
                    setResetLoad(generateRandomNumber(1, 99999999));
                    setLoad(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zapisano</p>` }) 
                }else{
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }
    
    const insertCategories = (data) => {
        let newCategoryArray = [];
        data && data.map(item => {
            newCategoryArray = [...newCategoryArray, item];
        })
        setCategoriesArray(newCategoryArray);
    }

    const threeDOptions = [
        {
            value: "false",
            text: "nie pokazuj"
        },
        {
            value: "true",
            text: "pokaż"
        },
    ]

    const unitOptions = [
        {
            value: "m2",
            text: "m2",
        },
        {
            value: "szt.",
            text: "szt."
        }
    ]

    let openPreviewVersion = () => {
        for (let i = 0; i < allVersions.length; i++) {
            const el = allVersions[i];
            if ( el.value == version ) {
                let url;
                if ( pagePreviewUrl.indexOf('?key') > -1  ) {
                    url = pagePreviewUrl.slice(0, pagePreviewUrl.indexOf('?key') );
                } else {
                    url = pagePreviewUrl;
                }
                window.open(`${ url }?key=${ el.key }`,'_blank');
                break;
            }
        }
    }

    let keyPressed = (e, action) => {
        if ( e.code === "Enter" ) {
            action();
        }
    }

    const removeItemColour = (id) => {
        let newData = coloursTechnicalDesc.filter((item, i) => item.image.id === id ? false : item);
        setColoursTechnicalDesc(newData);
    }

    const removeItemGalleryMT = (id) => {
        let newData = galleryMT.filter((item, i) => item.image.id === id ? false : item);
        setGalleryMT(newData);
    }

    const addItemColour = (item) => {
        let newData = [...coloursTechnicalDesc, {
            image: item,
        }]
        setColoursTechnicalDesc(newData);
    }

    const addItemGalleryMT = (item) => {
        let newData = [...galleryMT, {
            image: item,
        }]
        setGalleryMT(newData);
    }

    const getDataCatChildrens = (items) => {
        let newData = [{value: 0, text: "Nie wybrano"}];
        items.map((item, index) => {
            if(item.language == resultPage.language.id) {
                newData = [...newData, {
                    value: item.id,
                    text: item.name
                }]
            }
        })
        return newData;
    }

    return (
        <div className="container page-container">
            <PageTitle title="Edytuj stronę" />
            <div className="wrapper">
                <div className="form">
                    {allVersions && (postType == "pages" || postType == "products") &&
                        <div className="input-row btns">
                            <Select name="versions-list"
                                label="Wybierz wersję, którą chcesz przywrócić"
                                data={ allVersions } 
                                value={ version }
                                withUser
                                forceValidate={ forceValidate }
                                resetIndex={ resetVersion }
                                handle={ (val) => { setVersion(val) } } />

                            { ( version && version !== 'false' ) && 
                                <div className="buttons-version">
                                    <Button title="Podejrzyj wersję" setFunction={() => openPreviewVersion() } />
                                    <Button title="Przywróć wersję" setFunction={() => save() } />
                                </div>
                            }
                        </div>
                    }
                    <div className="input-row">
                        <Input  name="title"
                            required={ true }
                            label="Tytuł strony"
                            type="text" 
                            value={ title }
                            disabled={ false } 
                            setFunction={ (val) => setTitle(val) } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {resultPage && pagePreviewUrl &&
                        <div className="input-row row-slug">
                            <a href={pagePreviewUrl} target="_blank" tabIndex="0" aria-label={"Otwórz podgląd strony"} role="button">{pagePreviewUrl}</a>
                        </div>
                    }
                    {(postType == "brands" || postType == "human-touch") &&
                    <>
                        <div className="input-row">
                            <Editor required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeak(val) } value={ sneakpeak } label="Zajawka na liste" />
                        </div>
                        <div className="input-row">
                            <MediaLoad required={true} forceValidate={ forceValidate } setFunction={ val => setSneakpeakImage(val) } value={ sneakpeakImage } label="Zdjęcie zajawka na liste" />
                        </div>
                    </>
                    }
                    {postType == "brands" &&
                        <div className="input-row">
                            <Input  name="url"
                                required={ true }
                                label="Link do strony zewnętrznej"
                                type="text" 
                                value={ url }
                                disabled={ false } 
                                setFunction={ (val) => setUrl(val) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {/* {postType !== "pages" && 
                    <>
                        <div className="input-row">
                            <Textarea  name="desc"
                                label="Zajawka na liste"
                                value={ sneakpeak }
                                setFunction={ (val) => setSneakpeak(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                        <div className="input-row">
                            <Select name="top-date"
                                label="Wyłącz date i tag u góry wpisu"
                                data={ disableTopOptions } 
                                value={ disableTopDate }
                                forceValidate={ forceValidate }
                                handle={ (val) => { setDisableTopDate(val) } } />
                        </div>
                        <div className="input-row">
                            <Select name="important"
                                label="Wyróżnienie"
                                data={ importantOptions } 
                                value={ important }
                                forceValidate={ forceValidate }
                                handle={ (val) => { setImportant(val) } } />
                        </div>
                    </>
                    } */}
                    {postType !== "realizations" && postType !== "accessories" && postType !== "collections" && postType !== "marketing-support" &&
                        <div className="input-row">
                            <Textarea  name="seo_description"
                                label="SEO - opis"
                                value={ seoDescription }
                                setFunction={ (val) => setSeoDescription(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                    }
                    {postType == "realizations" &&
                        <>
                            <div className="input-row">
                                <Input  name="city"
                                    required={ true }
                                    label="Miasto"
                                    type="text" 
                                    value={ city }
                                    disabled={ false } 
                                    setFunction={ (val) => setCity(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Gallery 
                                    value={ galleryRealization }
                                    setFunction={ (val) => setGalleryRealization(val) }
                                    forceValidate={ forceValidate }
                                />
                            </div>
                        </>
                    }
                    {postType == "marketing-support" &&
                        <>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ true }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ true }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ true }
                                    label="Wysokość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            {/* <div className="input-row">
                                <Input  name="index"
                                    required={ true }
                                    label="Index"
                                    type="number" 
                                    value={ indexProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setIndexProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div> */}
                            <div className="input-row">
                                <Input  name="price-netto"
                                    required={ true }
                                    label="Cena Netto"
                                    // type="number" 
                                    value={ priceNetto }
                                    disabled={ false } 
                                    setFunction={ (val) => setPriceNetto(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="langs"
                                    required={ true }
                                    label="Języki (zapisywać po średniku pl;en)"
                                    type="text" 
                                    value={ langsProduct }
                                    disabled={ false } 
                                    setFunction={ (val) => setLangsProduct(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setMarketingDescription(val) } value={ marketingDescription } label="Opis" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setMarketingDescriptionAfterPrice(val) } value={ marketingDescriptionAfterPrice } label="Opis po cenie" />
                            </div>
                            {/* <div className="input-row">
                                <FilesDownload 
                                    data={ filesDownload }
                                    setFunction={ (val) => setFilesDownload(val) }
                                    forceValidate={ forceValidate } />
                            </div> */}
                            <div className="input-row">
                                <MediaLoad setFunction={ val => addItemGalleryMT(val)} value={ galleryMT } only="image" type="gallery" items={ galleryMT } removeItem={(id) => removeItemGalleryMT(id)} label="Galeria" changeRDLO={ (items) => setGalleryMT(items) } />
                            </div>
                        </>
                    }
                    {postType == "collections" &&
                    <>
                        <div className="input-row">
                            <Textarea  name="collection_desc"
                                label="Opis kolekcji"
                                value={ collectionDesc }
                                setFunction={ (val) => setCollectionDesc(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                        {resultPage?.language?.id &&
                            <>
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Opisy techniczne"
                                        value={ chooseTD }
                                        handle={ (val) => setChooseTD(val) }
                                        langID={resultPage.language.id} 
                                        column="xs-12" />
                                </div>
                                <div className="select-boxes">
                                    <SelectItems
                                        label="Kolory"
                                        value={ chooseColors }
                                        handle={ (val) => setChooseColors(val) } 
                                        langID={resultPage.language.id} 
                                        column="xs-12" />
                                </div>
                            </>
                        }
                        <div className="input-row categories-select">
                            {resultPage && categories && categories.length > 0 && categories.map((cat, index) => {
                                if(cat.name == "Kategorie akcesorii") {
                                    return (
                                        <Select name="accessories"
                                            label="Wybierz grupe akcesoriów do załadowania"
                                            data={ getDataCatChildrens(cat.childrens) } 
                                            value={ accessoriesValue }
                                            handle={ (val) => setAccessoriesValue(val) } 
                                            class="btn-primary btn-simple" />
                                    )
                                    // return (
                                    //     <Categories label="Wybierz grupe akcesoriów do załadowania" languagePageID={resultPage.language.id} postType={postType} meta={resultPage && resultPage.category} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                                    // )
                                }
                            })}
                        </div>
                        {/* <div className="input-row">
                            <MediaLoad setFunction={ val => addItemColour(val)} value={ coloursTechnicalDesc } only="image" type="gallery" items={ coloursTechnicalDesc } removeItem={(id) => removeItemColour(id)} label="Kolory" />
                        </div> */}
                    </>
                    }
                    {postType == "products" &&
                        <>
                        {resultPage?.language?.id &&
                            <div className="select-boxes">
                                <SelectItems
                                    label="Marka"
                                    limit={1}
                                    value={ chooseBrands }
                                    handle={ (val) => setChooseBrands(val) } 
                                    langID={resultPage.language.id} 
                                    forceValidate={ forceValidate } 
                                    column="xs-12" />
                            </div>
                        }
                        </>
                    }
                    {postType == "accessories" &&
                        <>
                        <div className="input-row">
                            <Input  name="dimensions"
                                required={ true }
                                label="Wymiary"
                                type="text" 
                                value={ dimensions }
                                disabled={ false } 
                                setFunction={ (val) => setDimensions(val) } 
                                forceValidate={ forceValidate }
                                class="btn-primary btn-simple" />
                        </div>
                        <div className="input-row">
                            <Textarea  name="info"
                                label="Informacja"
                                value={ info }
                                setFunction={ (val) => setInfo(val) } 
                                class="btn-primary btn-simple" />
                        </div>
                        </> 
                    }
                    {postType == "technical-description" &&
                        <>
                            <div className="input-row">
                                <Input  name="line"
                                    required={ true }
                                    label="Line"
                                    type="text" 
                                    value={ line }
                                    disabled={ false } 
                                    setFunction={ (val) => setLine(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ false }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ false }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ false }
                                    label="Długość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="ct-items"
                                    required={ false }
                                    label="Ilość w opakowaniu"
                                    type="number" 
                                    min="0"
                                    value={ ctItems }
                                    disabled={ false } 
                                    setFunction={ (val) => setCtItems(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Select name="unit"
                                    label="Jednostka (Ilość w opakowaniu)"
                                    data={ unitOptions } 
                                    value={ String(unit) }
                                    handle={ (val) => setUnit(val) } 
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Editor required={false} forceValidate={ forceValidate } setFunction={ val => setTechnicalDescription(val) } value={ technicalDescription } label="Opis niestandardowy" />
                            </div>
                            {/* <div className="input-row">
                                <MediaLoad setFunction={ val => addItemColour(val)} value={ coloursTechnicalDesc } only="image" type="gallery" items={ coloursTechnicalDesc } removeItem={(id) => removeItemColour(id)} label="Kolory" />
                            </div> */}
                        </>
                    }
                    {postType == "colors" &&
                        <>
                            <div className="input-row">
                                <Input  name="smallHeader"
                                    required={ false }
                                    label="Mały nagłówek nad tytułem"
                                    type="text" 
                                    value={ smallHeader }
                                    disabled={ false } 
                                    setFunction={ (val) => setSmallHeader(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="width"
                                    required={ false }
                                    label="Szerokość w cm"
                                    type="text" 
                                    value={ width }
                                    disabled={ false } 
                                    setFunction={ (val) => setWidth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="height"
                                    required={ false }
                                    label="Wysokość w cm"
                                    type="text" 
                                    value={ height }
                                    disabled={ false } 
                                    setFunction={ (val) => setHeight(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="length"
                                    required={ false }
                                    label="Długość w cm"
                                    type="text" 
                                    value={ length }
                                    disabled={ false } 
                                    setFunction={ (val) => setLength(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            <div className="input-row">
                                <Input  name="depth"
                                    required={ false }
                                    label="Grubość w cm"
                                    type="text" 
                                    value={ depth }
                                    disabled={ false } 
                                    setFunction={ (val) => setDepth(val) } 
                                    forceValidate={ forceValidate }
                                    class="btn-primary btn-simple" />
                            </div>
                            {threeDIcon &&
                                <div className="input-row">
                                    <Select name="icon3d"
                                        label="Ikona 3D"
                                        data={ threeDOptions } 
                                        value={ String(threeDIcon) }
                                        handle={ (val) => setThreeDIcon(val) } 
                                        class="btn-primary btn-simple" />
                                </div>
                            }
                            <div className="input-row">
                                <IconDesc 
                                    data={ iconDescData }
                                    setFunction={ (val) => setIconDescData(val) }
                                    forceValidate={ forceValidate }
                                />
                            </div>
                            <div className="input-row">
                                <div className="row-section">
                                    {resultPage?.language?.id &&
                                        <SelectItems
                                            label="Przypisz kolor do"
                                            data={ products } 
                                            value={ colorOfProducts }
                                            handle={ (val) => setColorOfProducts(val) } 
                                            forceValidate={ forceValidate } 
                                            // filter={true}
                                            langID={resultPage.language.id} 
                                            column="xs-12" />
                                    }
                                </div>
                            </div>
                        </>
                    }
                    {sections !== "empty" && resultPage && resultPage.language && resultPage.language.id &&
                        <div className="input-row">
                            <Sections setSections={(data) => setSections(data)} sections={sections} resetLoad={resetLoad} validateSections={validateSections} edit={true} setRemoveSection={(id) => setRemoveSections([...removeSections, id])} langID={resultPage.language.id} postType={postType} />
                        </div>
                    }
                    <div className="input-row save-row">
                        <Button title="Zapisz" setFunction={() => validateForm() } class={ `${blockedBtn ? ' disabled' : ''}` } />
                    </div>
                </div>
                <div className="boxes">
                    {date && 
                        <DateTime date={date} setFunction={(val) => setDate(val)} />
                    }
                    {/* {postType == "articles" && (lastClickedRadioCategory.name == "Wydarzenia" || lastClickedRadioCategory.name == "Event") &&
                        <DateTimeRangerPicker date={dateRange} setFunction={(val) => setDateRange(val)} />
                    } */}
                    {status &&
                        <StatusPage status={status} updateStatus={(val) => setStatus(val)} />
                    }
                    {postType !== "realizations" &&
                        <LanguagesSettings page={resultPage} pageID={pageID} changePage={(to) => props.history.push({ pathname: to })} postType={postType} />
                    }
                    {postType == "accessories" && resultPage && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie akcesorii") {
                            return (
                                <Categories languagePageID={resultPage.language.id} postType={postType} meta={resultPage && resultPage.category} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}
                    {postType == "products" && resultPage && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie") {
                            return (
                                <Categories languagePageID={resultPage.language.id} postType={postType} meta={resultPage && resultPage.category} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}
                    {postType == "marketing-support" && resultPage && categories && categories.length > 0 && categories.map((cat, index) => {
                        if(cat.name == "Kategorie marketing support") {
                            return (
                                <Categories languagePageID={resultPage.language.id} postType={postType} meta={resultPage && resultPage.category} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                            )
                        }
                    })}
                    {/* {resultPage && resultPage.language && resultPage.language.id &&
                        <Tags page={resultPage} type={postType} languagePageID={resultPage.language.id} />
                    }
                    {resultPage && categories && categories.length > 0 && categories.map((cat, index) => (
                        <Categories languagePageID={resultPage.language.id} postType={postType} meta={resultPage && resultPage.category} data={cat} index={index} insertCategories={(data) => insertCategories(data)} forceValidate={ postType == "articles" && validateSections } clickedItem={(item) => setLastClickedRadioCategory(item)} />
                    ))} */}
                    <ImagePost img={previewImage} setFunction={(val) => setPreviewImage(val)} forceValidate={ validateSections } />
                    <div className={ `btn-fix ${blockedBtn ? 'disabled' : ''}` } tabIndex="0" aria-label="Zapisz strone" onClick={() => validateForm()} onKeyDown={(e) => keyPressed(e, validateForm)}>
                        <SaveIcon />
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default Page;
