import React, { useEffect, useState } from 'react';
import Title from './../../../components/title/Title';
import Button from './../../../components/button/Button';
import Input from './../../../components/input/Input';
import Pagination from './../../../components/pagination/Pagination';
import ApiUrl from './../../../services/ApiUrl';
import axios from 'axios';
import mp4Icon from './../../../images/mp4.png';
import mp3Icon from './../../../images/mp3.png';
import ytIcon from './../../../images/yt.png';
import AttachmentIcon from './../../../images/attachment.png';
import Loader from './../../../components/loader/Loader';
import MediaItemPopup from './MediaItemPopup';
import UploadPopup from './UploadPopup';
import { useDispatch } from 'react-redux';
import PageTitle from './../../../components/page-title/PageTitle';

const Welcome = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let [ searchValue, setSearchValue ] = useState("");
    let [ gallery, setGallery ] = useState(false);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ pageSize, setPageSize ] = useState(20);
    let [ maxPages, setMaxPages ] = useState(1);
    let [ openIndex, setOpenIndex ] = useState(999999);
    let [ haveFirstData, setHaveFirstData ] = useState(false);
    let [ searchActive, setSearchActive ] = useState(false);
    let [ uploadPopup, setUploadPopup ] = useState(false);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await setLoader(true);
                await setCurrentPage(1);
                await getFilterData();
                await setLoad(true);
            })()
        }
    },[load]);

    const refreshDataTable = async(pageNumber, size = false, searchClicked = false) => {
        await setLoader(true);
        await setCurrentPage(pageNumber);
        if(size) {
            await setPageSize(size);   
        }
        await getFilterData(pageNumber, size, searchClicked);
    }
    const getFilterData = (pageNumber = false, size = false, searchClicked = false) => {
        pageNumber = pageNumber ? pageNumber : currentPage;
        size = size ? size : pageSize
        let search = (searchActive || searchClicked) ? searchValue : null;
        axios({
            method: 'get',
            url: `${ApiUrl()}/galleryItemsPagination?currentPage=${pageNumber}&pageSize=${size}&search=${search}`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            if(response.status == 200) {
                let res = JSON.parse(response.request.response);
                setMaxPages(res.meta.last_page);
                setGallery(res.data);
                setLoader(false);
                if(!haveFirstData) {
                    setHaveFirstData(true);
                }
            }else{
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
            }
        })
    }

    const searchDataTable = async() => {
        if(searchValue.length == 0) {
            await setLoad(false);
            await setSearchActive(false);
        }else{
            await setSearchActive(true);
            await refreshDataTable(1, false, true)
        }
    }

    const handleKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }

    const removeImage = async(imageID, index) => {
        await setLoader(true)
        await axios({
            method: 'post',
            url: `${ApiUrl()}/removeImageLibrary`,
            data: {
                imageID: imageID,
                token: localStorage.getItem('token')
            },
        }).then(async response => {
            let res = JSON.parse(response.request.response);
            // refreshDataTable(currentPage, false, false);
            let newGallery = gallery;
            newGallery.splice(index, 1);
            await setGallery(newGallery);
            await setLoader(false)
        })
    }

    const addImageToTable = async(vals) => {
        await setLoader(true)
        let copyGallery = gallery;
        let newGallery = [];
        vals.map(val => {
            val.alts = val.alt;
            newGallery = [...newGallery, val]
            copyGallery.splice(copyGallery.length - 1, 1);
        })
        newGallery = [...newGallery, ...copyGallery];
        await setGallery(newGallery);
        await setLoader(false)
    }

    return (
        <div className="container media-library-container">
            <div className="wrapper">
                {loader &&
                    <div className="loader-wrapper">
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                }
                {uploadPopup &&
                    <UploadPopup close={ () => setUploadPopup(false) } addImageToTable={ (val) => addImageToTable(val) } refreshGallery={() => refreshDataTable(1, false, false)} />
                }
                {/* <Title title="Biblioteka mediów" /> */}
                <PageTitle title="Biblioteka mediów" />
                <div className="action-inputs">
                    <Button title="Dodaj nowe" class="btn-secondary" setFunction={ () => setUploadPopup(true) } />
                    <Input  
                        type={ "search" }
                        required={ false }
                        setFunction={ val => setSearchValue(val) }
                        onClickEnter={ () => searchDataTable() }
                        value={ searchValue }
                        placeholder={"Wyszukaj"}
                        ariaLabel="Wyszukaj media po nazwie"
                        regExp={ /.*/ }
                        minChar={ 0 } 
                    />
                </div>
                <div className="table-media">
                    <div className="images-wrapper">
                        {gallery && gallery.map((item, index) => (
                            <div key={index} className={"image-box " + (item.src.split('.').pop().substring(0, 3))} tabIndex="0" role="button" aria-label={`Edytuj ${item.name}`}>
                                {(item.type == "image" || item.type == "vector") &&
                                    <img src={item.sizes.small} alt={item.alts[0].value} onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)} />
                                }
                                {item.type == "video" &&
                                    <img src={mp4Icon} className="bg-svg" alt={item.alts[0].value} onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)} />
                                }
                                {item.type == "lector" &&
                                    <img src={mp3Icon} className="bg-svg" alt={item.alts[0].value} onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)} />
                                }
                                {item.type == "video-url" &&
                                    <img src={ytIcon} className="bg-svg" alt={item.alts[0].value} onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)} />
                                }
                                {item.type == "attachment" &&
                                    <img src={AttachmentIcon} className="bg-svg" alt={item.alts[0].value} onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)} />
                                }
                                <div className="remove-icon">
                                    <i className="material-icons" 
                                        onClick={() => removeImage(item.id, index)}
                                        aria-label="Usuń media"
                                        tabIndex="0" 
                                        role="button" 
                                        onKeyDown={(e) => handleKeyDown(e, removeImage, item.id, index)}>close</i>
                                </div>
                                <div className="simple-info-image" onClick={ () => setOpenIndex(index) } onKeyDown={(e) => handleKeyDown(e, setOpenIndex, index)}>
                                    <div className="info-row">
                                        <span className="label">Nazwa: </span>
                                        <span className="value">{item.name}</span>
                                    </div>
                                    {item.size &&
                                        <div className="info-row">
                                            <span className="label">Rozmiar: </span>
                                            <span className="value">{item.size}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {openIndex !== 999999 &&
                        <MediaItemPopup page={currentPage} gallery={gallery} data={gallery[openIndex]} openIndex={openIndex} setIndex={() => setOpenIndex(999999)} updateGalleryItems={(newGallery) => setGallery(newGallery)} refreshGallery={() => refreshDataTable(currentPage)} />   
                    }
                </div>
                <div className="pagination">
                    {haveFirstData &&
                        <Pagination currentPage={currentPage} maxPages={maxPages} pageSize={pageSize} setPageSize={(size) => refreshDataTable(1, size) } changeCurrentPage={(newCurrent) => refreshDataTable(newCurrent)} />
                    }
                </div>
            </div>
        </div>
    );
}

export default Welcome;
