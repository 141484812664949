import React, { useState, useEffect } from 'react'
import Checkbox from './../checkbox/Checkbox';
import Radio from './../radio/Radio';

const Categories = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ reset, setReset ] = useState(0);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ categoryData, setCategoryData ] = useState([]);
    // let category = props.data;
    let [ category, setCategory ] = useState(props.data);
    let meta = props.meta
    let [ errorText, setErrorText ] = useState( false );
    let [ error, setError ] = useState( false );

    useEffect(() => {
        if(props.reload && load || props.meta && load) {
            setLoad(false);
        }
    }, [props.reload, props.meta])

    useEffect(() => {
        if(!load && category && category.childrens && (category.childrens).length > 0) {
            createDataCategory();
            setLoad(true);
        }
    }, [load, category, meta])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        let countChecked = 0;
        categoryData.map(item => {
            if(item.value) {
                countChecked++;
            }
        })
        if(countChecked == 0) {
            setError(true)
            setErrorText('Należy wybrać kategorie');
        }else{
            setError(false);
            setErrorText('');
        }
    }

    const checkChecked = (catItem) => {
        let status = false;
        let metaID = null;
        if(meta && meta.length > 0) {
            props.meta.map(meta => {
                if(meta.data && meta.data.name == catItem.name && meta.type == "category_item") {
                    status = true;
                    metaID = meta.id
                }
            })
        }
        return {
            status: status,
            metaID: metaID
        };
    }

    const createDataCategory = () => {
        let newData = [];
        if(category && category.childrens) {
            category.childrens.map(cat => {
                if(props.languagePageID && cat.language == props.languagePageID) {
                    let checkItem = checkChecked(cat);
                    newData = [...newData, {
                        catItemId: cat.id,
                        name: cat.name,
                        value: meta ? checkItem.status : false,
                        metaID: meta ? checkItem.metaID : null,
                        update: false,
                    }]
                }
            })
        }
        console.log(newData)
        props.insertCategories(newData);
        setCategoryData(newData);
    }

    const update = (value, id) => {
        let newCategoriesItemsArray = categoryData;
        if(props.postType == "articles") {
            let clickedItem = false;
            newCategoriesItemsArray.filter(item => item.catItemId == id ? (item['value'] = value, item['update'] = true, clickedItem = item) : (item['value'] = false, item['update'] = true));
            if(clickedItem) {
                props.clickedItem(clickedItem)
            }
        }else{
            newCategoriesItemsArray.filter(item => item.catItemId == id ? (item['value'] = value, item['update'] = true) : item);
        }
        props.insertCategories(newCategoriesItemsArray);
        setCategoryData(newCategoriesItemsArray);
    }

    return (
        <div className={error ? "categories settings-box error" : "categories settings-box"} data-alert={"Należy wybrać kategorie"}>
            <div className="header">
                <span>{props.label ? props.label : category.name}</span>
            </div>
            <div className="content">
                {categoryData && categoryData.map((cat, i) => (
                    <>
                        {props.postType == "articles" &&
                            <Radio required={ true }
                                name="category"
                                setFunction={ (value) => update(value, cat.catItemId) }
                                text={ cat.name }
                                forceValidate={ forceValidate } 
                                reset={ reset } 
                                data={ {index: i, value: cat.value} }
                                class="" />
                        }
                        {props.postType !== "articles" &&
                            <Checkbox required={ true }
                                setFunction={ (value) => update(value, cat.catItemId) }
                                text={ cat.name }
                                forceValidate={ forceValidate } 
                                reset={ reset } 
                                data={ {index: i, value: cat.value} }
                                class="" />
                        }
                    </>
                ))}
                {error &&
                    <span class="error-text">Należy wybrać jedną z kategorii</span>
                }
            </div>
        </div>
    );
}

export default Categories;
