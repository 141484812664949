import React, { useState, useEffect } from 'react'
import Banner from './sections-components/banner/Banner';
import BoxReadMore from './sections-components/box-read-more/BoxReadMore';
import BoxImageReadMore from './sections-components/box-image-read-more/BoxImageReadMore';
import BoxTextLinks from './sections-components/box-text-links/BoxTextLinks';
import BoxHeaderLinks from './sections-components/box-header-links/BoxHeaderLinks';
import Video from './sections-components/video/Video';
import BannerTitle from './sections-components/banner-title/BannerTitle';
import Thx from './sections-components/thx/Thx';
import LogosInline from './sections-components/logos-inline/LogosInline';
import Gallery from './sections-components/gallery/Gallery';
import FilesToDownload from './sections-components/files-download/FilesDownload';
import EnvironmentBoxes from './sections-components/environment-boxes/EnvironmentBoxes';
import ContactForm from './sections-components/contact-form/ContactForm';
import ContactInfo from './sections-components/contact-info/ContactInfo';
import BoxesBtnText from './sections-components/boxes-btn-text/BoxesBtnText';
import ImageDescription from './sections-components/image-description/ImageDescription';
import Realizations from './sections-components/realizations/Realizations';
import ColorsList from './sections-components/colors-list/ColorsList';
import HumanTouchList from './sections-components/human-touch/HumanTouch';
import BrandsList from './sections-components/brands-list/BrandsList';
import Patterns from './sections-components/patterns/Patterns';
import ImagesDesc from './sections-components/images-desc/ImagesDesc';
import TableImage from './sections-components/table-image/TableImage';
import Collections from './sections-components/collections/Collections';
import Accessories from './sections-components/accessories/Accessories';
import MarketingSupport from './sections-components/marketing-support/MarketingSupport';
import ProductsFilter from './sections-components/products-filter/ProductsFilter';
import Editor from './sections-components/editor/Editor';
import IconsDesc from './sections-components/icons-desc/IconsDesc';
import Header from './sections-components/header/Header';
import Documents from './sections-components/documents/Documents';
import GhostHeader from './sections-components/ghost-header/GhostHeader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfoIcon from './../../svg-components/InfoIcon';
import BannerImage from './../../images/sections/banner.png';
import BoxReadMoreImage from './../../images/sections/box-read-more.png';
import BoxImageReadMoreImage from './../../images/sections/box-image-read-more.png';
import BoxTextLinksImage from './../../images/sections/box-text-links.png';
import BoxHeaderLinksImage from './../../images/sections/box-header-links.png';
import AccessoriesNeededImage from './../../images/sections/AccessoriesNeeded.png';
import BannerTitleImage from './../../images/sections/BannerTitle.png';
import BoxBtnTextImage from './../../images/sections/BoxBtnText.png';
import BrandBoxesImage from './../../images/sections/BrandBoxes.png';
import ChooseColorImage from './../../images/sections/ChooseColor.png';
import ContactInfoImage from './../../images/sections/ContactInfo.png';
import ContactUsImage from './../../images/sections/ContactUs.png';
import EnvironmentBoxesImage from './../../images/sections/EnvironmentBoxes.png';
import FilesToDownloadImage from './../../images/sections/FilesToDownload.png';
import FilterProductsImage from './../../images/sections/FilterProducts.png';
import GalleryImage from './../../images/sections/Gallery.png';
import ImageDescriptionImage from './../../images/sections/ImageDescription.png';
import LogosInlineImage from './../../images/sections/LogosInline.png';
import ProductsToBuyImage from './../../images/sections/ProductsToBuy.png';
import RealizationsImage from './../../images/sections/Realizations.png';
import SlineItemsImage from './../../images/sections/SlineItems.png';
import ThxImage from './../../images/sections/Thx.png';
import VideoImage from './../../images/sections/video.png';
import BoxBtnTextHumanImage from './../../images/sections/BoxBtnTextHuman.png';
import GhostHeaderImage from './../../images/sections/ghostHeader.png';
import PatternsImage from './../../images/sections/patterns.png';
import ImagesDescImage from './../../images/sections/imagesDesc.png';
import TableImageImage from './../../images/sections/table.png';
import EditorImage from './../../images/sections/editor.png';
import IconsDescImage from './../../images/sections/iconsDesc.png';
import HeaderImage from './../../images/sections/header.png';
import TechnicalDesc from './sections-components/technical-desc/TechnicalDesc';
import TechnicalDescImage from './../../images/sections/technicalDesc.png';
import PagesList from './sections-components/pages-list/PagesList';
import Get from './../../services/Get';

let sectionsData = [];
const Sections = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ sections, setSections ] = useState([]);
    let [ imagePopup, setImagePopup ] = useState(false);
    let [ openedImage, setOpenedImage ] = useState('');
    let relation = Get('relation');
    
    let sectionsList = [
        {
            // BannerVideo
            id: null,
            name: "Banner",
            section: "banner_section",
            component: <Banner onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BannerImage
        },
        {
            // BannerVideo
            id: null,
            name: "Odstęp od menu (zamiast baneru)",
            section: "ghost_header_section",
            component: <GhostHeader onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: GhostHeaderImage
        },
        {
            //TextBoxGrayWhite 
            id: null,
            name: "Box z czytaj więcej",
            section: "box_read_more_section",
            component: <BoxReadMore onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxReadMoreImage
        },
        {
            //PhotoTextLarge
            id: null,
            name: "Box ze zdjęciem w tle i czytaj więcej",
            section: "box_image_read_more_section",
            component: <BoxImageReadMore onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxImageReadMoreImage
        },
        {
            //MarkDescriptionBox
            id: null,
            name: "Box z opisem marki",
            section: "box_text_links_section",
            component: <BoxTextLinks onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxTextLinksImage
        },
        {
            //Newsroom
            id: null,
            name: "Box z nagłówkiem i kafelkami linkującymi",
            section: "box_header_links_section",
            component: <BoxHeaderLinks onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxHeaderLinksImage
        },
        {
            // VidoPlayer
            id: null,
            name: "Video",
            section: "video_section",
            component: <Video onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: VideoImage
        },
        {
            // BannerTitle
            id: null,
            name: "Banner z tytułem",
            section: "banner_title_section",
            component: <BannerTitle onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BannerTitleImage
        },
        {
            // Thx
            id: null,
            name: "Podziękowanie",
            section: "thx_section",
            component: <Thx onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ThxImage
        },
        {
            // LogosInline
            id: null,
            name: "Loga marek",
            section: "logos_inline_section",
            component: <LogosInline onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: LogosInlineImage
        },
        {
            // Gallery
            id: null,
            name: "Galeria",
            section: "gallery_section",
            component: <Gallery onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: GalleryImage
        },
        {
            // FilesToDownload
            id: null,
            name: "Pliki do pobrania",
            section: "files_download_section",
            component: <FilesToDownload onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: FilesToDownloadImage
        },
        {
            // EnvironmentBoxes
            id: null,
            name: "Boxy środowiskowe",
            section: "environment_boxes_section",
            component: <EnvironmentBoxes onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: EnvironmentBoxesImage
        },
        {
            // ContactUs
            id: null,
            name: "Formularz kontaktowy",
            section: "contact_form_section",
            component: <ContactForm onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ContactUsImage
        },
        {
            // ContactInfo
            id: null,
            name: "Informacje kontaktowe",
            section: "contact_info_section",
            component: <ContactInfo onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ContactInfoImage
        },
        {
            // BoxBtnText
            id: null,
            name: "Boxy ze zdjęciem i edytorem",
            section: "boxes_image_editor_section",
            component: <BoxesBtnText onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxBtnTextImage
        },
        {
            //BoxBtnTextHuman 
            id: null,
            name: "Human touch lista",
            section: "human_houch_list_section",
            component: <HumanTouchList onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BoxBtnTextHumanImage
        },
        {
            // ImageDescription
            id: null,
            name: "Zdjęcie z opisem",
            section: "image_description_section",
            component: <ImageDescription onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ImageDescriptionImage
        },
        {
            // Realizations
            id: null,
            name: "Realizacje",
            section: "realizations_section",
            component: <Realizations onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: RealizationsImage
        },
        {
            // ChooseColor
            id: null,
            name: "Lista kolorów",
            section: "colors_list_section",
            component: <ColorsList onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ChooseColorImage
        },
        {
            // BrandBoxes
            id: null,
            name: "Lista marek",
            section: "brands_section",
            component: <BrandsList onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: BrandBoxesImage
        },
        {
            // SlineItems, CombineSectionsProductLineSwitcher
            id: null,
            name: "Lista Kolekcji",
            section: "collections_section",
            component: <Collections onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: SlineItemsImage
        },
        {
            // AccessoriesNeeded
            id: null,
            name: "Lista akcesorii",
            section: "accessories_section",
            component: <Accessories onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: AccessoriesNeededImage
        },
        {
            // ProductsToBuy
            id: null,
            name: "Marketing Support",
            section: "marketing_support_list_section",
            component: <MarketingSupport onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: ProductsToBuyImage
        },
        {
            // FilterProducts
            id: null,
            name: "Lista produktów (kafelki z filtrowaniem)",
            section: "products_filter_list_section",
            component: <ProductsFilter onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: FilterProductsImage
        },
        {
            id: null,
            name: "Nagłówek/Tytuł",
            section: "header_section",
            component: <Header onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: HeaderImage
        },
        {
            id: null,
            name: "Opisy techniczne",
            section: "technical_desc_section",
            component: <TechnicalDesc onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: TechnicalDescImage,
        },
        {
            id: null,
            name: "Lista wybranych stron",
            section: "pages_list_section",
            component: <PagesList onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: FilterProductsImage
        },
        {
            id: null,
            name: "Edytor tekstowy",
            section: "editor_section",
            component: <Editor onChange={(newData, index) => updateDataSection(newData, index)} />,
            data: {},
            status: null,
            image: EditorImage
        },
        // {
        //     //LegalInforamtion
        //     id: null,
        //     name: "Lista dokumentów (filtrowanie)",
        //     section: "documents_section",
        //     component: <Documents onChange={(newData, index) => updateDataSection(newData, index)} />,
        //     data: {},
        //     status: null,
        //     image: FilterProductsImage
        // },
    ]

    if (props.postType == "technical-description") {
        sectionsList = [
            {
                id: null,
                name: "Wzory",
                section: "patterns_section",
                component: <Patterns onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: PatternsImage
            },
            {
                id: null,
                name: "Zdjęcia z opisem",
                section: "images_desc_section",
                component: <ImagesDesc onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: ImagesDescImage
            },
            {
                id: null,
                name: "Zdjęcie tabeli",
                section: "table_image_section",
                component: <TableImage onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: TableImageImage
            },
            {
                id: null,
                name: "Edytor tekstowy",
                section: "editor_section",
                component: <Editor onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: EditorImage
            },
            {
                id: null,
                name: "Ikony z opisem",
                section: "icons_desc_section",
                component: <IconsDesc onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: IconsDescImage
            },
            {
                //Newsroom
                id: null,
                name: "Box z nagłówkiem i kafelkami linkującymi",
                section: "box_header_links_section",
                component: <BoxHeaderLinks onChange={(newData, index) => updateDataSection(newData, index)} />,
                data: {},
                status: null,
                image: BoxHeaderLinksImage
            },
        ]
    } else if (props.postType == "collections" || props.postType == "marketing-support" || props.postType == "realizations" || props.postType == "colors") {
        sectionsList = false
    }

    useEffect(() => {
        if(props.resetLoad !== 0 && load) {
            setLoad(false);
        }
    }, [props.resetLoad])

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        setData();
    }, [load])

    const setData = () => {
        if(load || !props.edit) {
            setSections(props.sections)
            sectionsData = props.sections;


            // let loadSectionStart = props.sections;
            if(!sectionsData || sectionsData.length == 0)
            if(props.postType == "realizations") {
                sectionsList && sectionsList.map(section => {
                    if(section.name == "Realizacja") {
                        addSection(section)
                    }
                })
            }
            // setSections(loadSectionStart)
            // sectionsData = loadSectionStart;
            // console.log(loadSectionStart)
        } else {
            if(props.sections) {
                let newSectionsWithComponent = [];
                props.sections.map(section => {
                    sectionsList.map(list => {
                        if(section.section == list.section) {
                            let newSectionItem = section;
                            newSectionItem.component = list.component;
                            newSectionsWithComponent = [...newSectionsWithComponent, newSectionItem];
                        }
                    })
                })
                props.setSections(newSectionsWithComponent);
                sectionsData = newSectionsWithComponent;
                setSections(newSectionsWithComponent);
            }
        }
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.setSections(items);
        sectionsData = items;
        setSections(items)
    }

    const generateRandomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    const addSection = (newSection) => {
        newSection.id = generateRandomNumber(1, 99999999);
        let oldLengthSection = sectionsData.length;
        if(props.edit) {
            newSection.status = 'new';
        }
        if( sectionsData && sectionsData.length > 0) {
            sectionsData = [...sectionsData, newSection];
        }else{
            sectionsData = [newSection];
        }
        setSections(sectionsData)
        props.setSections(sectionsData)
        openNewSection(oldLengthSection)
    }

    const updateDataSection = (newData, index) => {
        sectionsData = sectionsData.filter((item, i) => i == index ? (item.data = newData) : item);
        props.setSections(sectionsData);
        setSections(sectionsData)
    }

    const removeSection = (i) => {
        let removeID;
        const removeItem = (id) => {
            removeID = id;
            return null;
        }
        sectionsData = sectionsData.filter((item, index) => index == i ? removeItem(item.id) : item);
        props.setSections(sectionsData);
        setSections(sectionsData)
        if(props.edit) {
            props.setRemoveSection(removeID)
        }
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll('.sections-component .section');
            sections[index].children[1].classList.toggle('active');
        }
    }

    const openNewSection = (oldLength) => {
        let interval = setInterval(function() { 
            let sections = document.querySelectorAll('.sections-component .section');
            if(oldLength < sections.length) {
                sections[sections.length - 1].children[1].classList.add('active');
                clearInterval(interval);
            }
        }, 200);
    }

    const getImageBySectionName = (sectionName) => {
        let section = sectionsList.filter(item => item.section == sectionName ? item.image : false);
        return section[0].image;
    }
    return (
        <>
        {sectionsList &&
        <div className="sections-box">
            <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="sections-component">
                    {(provided) => (
                        <div className="sections-component" {...provided.droppableProps} ref={provided.innerRef}>
                        {sections && sections.map((item, index) => (
                            <Draggable key={String(item.id)} draggableId={String(item.id)} index={index}>
                                {(provided) => provided && (
                                    <div className="section" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                            aria-label={`Zwiń lub rozwiń sekcje ${item.name}`}
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>{item.name}</span>
                                            <div className="svg-icon" onClick={() => { setOpenedImage(getImageBySectionName(item.section)); setImagePopup(true) }} aria-hidden={true}>
                                                <InfoIcon />
                                            </div>
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            {!relation &&
                                            <i onClick={() => removeSection(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeSection, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                            }

                                        </div>
                                        <div className="content">
                                            {item.component && React.cloneElement(item.component, { index, data: item.data, validate: props.validateSections, edit: props.edit, langID: props.langID })}
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-component">
                <ul className="sections-list">
                    {sectionsList.map((section, index) => (
                        <div className="item-clicked" 
                            key={index} 
                            // onClick={() => addSection(section) } 
                            tabIndex="0" 
                            role="button" 
                            aria-label={`Dodaj sekcje ${section.name}`}
                            onKeyDown={(e) => handleKeyDown(e, addSection, section)}>
                            <li onClick={() => addSection(section) } >
                                <p>{section.name}</p>
                            </li>
                            {section.image &&
                                <div className="svg-icon" onClick={() => { setOpenedImage(section.image); setImagePopup(true) }} aria-hidden={true}>
                                    <InfoIcon />
                                </div>
                            }
                        </div>
                    ))}
                </ul>
            </div>
            {imagePopup &&
                <div className="image-popup">
                    <div className="close" onClick={() => setImagePopup(false)}>Zamknij</div>
                    <img className="image-src" src={openedImage} alt="zdjecie sekcji" />
                </div>
            }
        </div>
        }
        </>
    );
}

export default Sections;
