import React, { useState, useEffect } from 'react'
import Select from './../../../select/Select';
import Input from './../../../input/Input';
import SelectItems from '../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector } from 'react-redux';

let validateNumber = 0;
const ColorsList = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ pages, setPages ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                header: '',
                pagination: '8',
                btnText: 'Load more',
                colors: [],
            }, props.index);
            // getPages();
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    header: '',
                    pagination: '8',
                    btnText: 'Load more',
                    colors: [],
                }, props.index);
            }
            // getPages();
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            links: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.colors-list-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const getPages = () => {
        axios({
            method: 'get',
            url: `${ApiUrl()}/pagesList?langID=${lang}&postType=colors`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            setPages(res.data);
        })
    }

    return (
        <div className={error ? `section-wrapper colors-list error-section colors-list-${props.index}` : `section-wrapper colors-list colors-list-${props.index}`}>
            <div className="row-section">
                <Input  required={ false }
                    setFunction={ val => updateValue(val, 'header') }
                    value={props.data.header}
                    label={ 'Nagłówek' }
                    regExp={ /.*/ }
                    minChar={ 3 } 
                    forceValidate={ forceValidate } 
                />
            </div>
            <div className="row-section">
                <Input  required={ true }
                    setFunction={ val => updateValue(val, 'pagination') }
                    value={props.data.pagination}
                    label={ 'Paginacja' }
                    regExp={ /^[0-9]+$/ }
                    minChar={ 1 } 
                    forceValidate={ forceValidate } 
                />
            </div>
            <div className="row-section">
                <Input  required={ true }
                    setFunction={ val => updateValue(val, 'btnText') }
                    value={props.data.btnText}
                    label={ 'Treść przycisku doładowującego' }
                    regExp={ /.*/ }
                    minChar={ 3 } 
                    forceValidate={ forceValidate } 
                />
            </div>
            {/* <div className="row-section">
                {pages && pages.length > 0 &&
                    <div className="select-boxes">
                        <SelectItems
                            label="Kolory"
                            data={ pages } 
                            value={ props.data.colors }
                            handle={ (val) => updateValue(val, 'colors') } 
                            forceValidate={ forceValidate } 
                            column="xs-12" />
                    </div>
                }
            </div> */}
        </div>
    );
}

export default ColorsList;