import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import ApiUrl from '../../../../services/ApiUrl';
import SelectItems from '../../../select-items/SelectItems';
import Select from '../../../select/Select';
import Input from '../../../input/Input';
import Gallery from '../../../gallery/Gallery';

let validateNumber = 0;
const PagesList = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID;

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({ 
                header: '',
                linksPages: [],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    header: '',
                    linksPages: [],
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.pages-list-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const options = [
        {
            value: "pages",
            text: "Strony"
        },
        {
            value: "images",
            text: "Zdjęcia"
        }
    ];

    return (
        <div className={error ? `section-wrapper pages-list error-section pages-list-${props.index}` : `section-wrapper pages-list pages-list-${props.index}`}>
                <div className="row-section">
                    <Input  required={ false }
                        setFunction={ val => updateValue(val, 'header') }
                        value={props.data.header}
                        label={ 'Nagłówek' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>

                <div className="row-section">
                    <SelectItems
                        label="Kafelki linkujące"
                        value={ props.data.linksPages }
                        handle={ (val) => updateValue(val, 'linksPages') } 
                        forceValidate={ forceValidate } 
                        filter
                        postType="all"
                        langID={lang}
                        column="xs-12" />
                </div>
        </div>
    );
}

export default PagesList;