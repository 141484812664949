import React, { useState, useEffect } from 'react'
import Input from './../../../input/Input';
import SelectItems from '../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector } from 'react-redux';
import Select from './../../../select/Select';

let validateNumber = 0;
const BrandsList = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                brands: [],
                reverse: "false",
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    brands: [],
                    reverse: "false",
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            links: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.brands-list-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const options = [
        {
            value: "false",
            text: "nie zamieniaj kolejności"
        },
        {
            value: "true",
            text: "zamień kolejność"
        },
    ]

    return (
        <div className={error ? `section-wrapper brands-list error-section brands-list-${props.index}` : `section-wrapper brands-list brands-list-${props.index}`}>
            <div className="row-section">
                <div className="select-boxes">
                    <SelectItems
                        label="Marki"
                        value={ props.data.brands }
                        handle={ (val) => updateValue(val, 'brands') } 
                        forceValidate={ forceValidate } 
                        langID={lang}
                        postType="brands"
                        column="xs-12" />
                </div>
            </div>
            <div className="row-section">
                <Select name="status"
                    data={ options } 
                    label="Zmiana kolejności wyświetlania"
                    value={ String(props.data.reverse) }
                    handle={ (val) => updateValue(val, 'reverse') } 
                    class="btn-primary btn-simple" />
            </div>
        </div>
    );
}

export default BrandsList;