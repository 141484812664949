import React, { useState, useEffect } from 'react'
import SelectItems from '../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector } from 'react-redux';

let validateNumber = 0;
const TechnicalDesc = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                items: [],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    items: [],
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.technical-description-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    return (
        <div className={error ? `section-wrapper technical-description error-section technical-description-${props.index}` : `section-wrapper technical-description technical-description-${props.index}`}>
            <div className="row-section">
                <div className="select-boxes">
                    <SelectItems
                        label="Opisy techniczne"
                        value={ props.data.items }
                        handle={ (val) => updateValue(val, 'items') } 
                        forceValidate={ forceValidate } 
                        langID={lang}
                        postType="technical-description"
                        column="xs-12" />
                </div>
            </div>
        </div>
    );
}

export default TechnicalDesc;