import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ApiUrl from '../../../../services/ApiUrl';
import axios from 'axios';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/select/Select';
import Button from '../../../../components/button/Button';
import Avatar from 'react-avatar-edit';
import PageTitle from '../../../../components/page-title/PageTitle';
import readTextNvda from './../../../../services/readTextNvda';
import DateTimePicker from 'react-datetime-picker';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';

const EditUser = ({ match }) => {
	let [ load, setLoad ] = useState(0);
	let [ login, setLogin ] = useState('');
	let [ email, setEmail ] = useState('');
	let [ name, setName ] = useState('');
	let [ surname, setSurname ] = useState('');
	let [ password, setPassword ] = useState('');
	let [ avatar, setAvatar ] = useState('');
	let [ accountTypeSelect, setAccountTypeSelect ] = useState(false);
	let [ forceValidate, setForceValidate ] = useState(false);
	let [ optionsAccountTypes, setOptionsAccountTypes ] = useState([]);
	let [ accountTypes, setAccountTypes ] = useState([]);
	let [ user, setUser ] = useState(false);
	let [ userID, setUserID ] = useState('');
	let [ blockedBtn, setBlockedBtn ] = useState(false);
	let [ infoPermission, setInfoPermission ] = useState('');
	let [ newAvatar, setNewAvatar ] = useState(false);
	let [ uploadExtFile, setUploadExtFile ] = useState('');
	let [ defaultAvatar, setDefaultAvatar ] = useState('');
	let [ profil, setProfil ] = useState(false);
	let [ expiredDate, setExpiredDate ] = useState(new Date());
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (typeof window !== 'undefined') {
				let param = window.location.pathname.split('/');
				param = param[param.length - 1];
				if (param == 'profil') {
					param = localStorage.getItem('userId');
					setUserID(localStorage.getItem('userId'));
					setProfil(true);
				} else {
					setUserID(param);
				}
				getUser(param);
			}
		},
		[ window ]
	);

	useEffect(
		() => {
			if (accountTypes && accountTypes.length > 0 && optionsAccountTypes.length == 0) {
				createOptionsAccountTypes();
			}
		},
		[ accountTypes, accountTypeSelect, userID ]
	);

	useEffect(
		() => {
			if (!load) {
				getAccountTypes();
				setLoad(true);
			}
		},
		[ load ]
	);

	const getUser = (param) => {
		axios({
			method: 'get',
			url: `${ApiUrl()}/getUserByID?userID=${param}`,
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		}).then((response) => {
			let res = JSON.parse(response.request.response);
			setUser(res.data[0]);
			setLogin(res.data[0].login);
			setEmail(res.data[0].email);
			setName(res.data[0].name);
			setSurname(res.data[0].surname);
			setAccountTypeSelect(res.data[0].account_type.id);
			setAvatar(res.data[0].avatar);
			setDefaultAvatar(res.data[0].avatar);
			setExpiredDate(new Date(res.data[0].expired_date));
		});
	};

	const getAccountTypes = () => {
		axios({
			method: 'get',
			url: `${ApiUrl()}/getAccountTypes`,
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
		}).then((response) => {
			let res = JSON.parse(response.request.response);
			setAccountTypes(res.data);
		});
	};

	const createOptionsAccountTypes = () => {
		let newOptions = [];
		accountTypes.map((type, i) => {
			let info = `<h2>${type.name}</h2><h3>Lista Uprawnień:</h3><ul>`;
			type.permissions &&
				type.permissions.map((perm) => {
					info = `${info}<li>${perm.name}</li>`;
				});
			newOptions = [
				...newOptions,
				{
					value: type.id,
					text: type.name,
					info: `${info}</ul>`
				}
			];
			// if (i == 0) {
			// 	setAccountTypeSelect(type.id);
			// 	setInfoPermission(info);
			// }
		});
		setOptionsAccountTypes(newOptions);
	};

	const generatePassword = () => {
		var length = 16,
			charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
			retVal = '';
		for (var i = 0, n = charset.length; i < length; ++i) {
			retVal += charset.charAt(Math.floor(Math.random() * n));
		}
		setPassword(retVal);
	};

	const validate = async () => {
		await setForceValidate(true);
		await wait(100);
		await setForceValidate(false);
		let errors = await checkErrors();
		if (!errors) {
			await createUser();
		} else {
		}
	};

	let checkErrors = () => {
		let result;
		let elements = document.querySelectorAll(`.new-user-container .error`);
		if (elements.length > 0) {
			readTextNvda("new-user");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
		} else {
			result = false;
		}
		return result;
	};

	async function wait(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	}

	const createUser = async () => {
		if (!blockedBtn) {
			setBlockedBtn(true);
			expiredDate = dayjs(expiredDate).locale('pl').format('YYYY-MM-DD');
			axios({
				method: 'post',
				url: `${ApiUrl()}/newAdmin`,
				data: {
					editUserID: userID,
					userID: localStorage.getItem('userId'),
					token: localStorage.getItem('token'),
					name: name,
					surname: surname,
					password: password && password.length > 0 ? password : false,
					accountType: accountTypeSelect,
					avatar: avatar,
					extFile: uploadExtFile,
					expiredDate: expiredDate,
					edit: true
				}
			})
				.then((response) => {
					let res = JSON.parse(response.request.response);
					if (res.status == 'success') {
						setPassword('');
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zaaktualizowano użytkownika</p>` }) 
						setBlockedBtn(false);
					} else {
						dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
						setBlockedBtn(false);
					}
				})
				.catch((e) => {
					dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` })
					setBlockedBtn(false);
				});
		}
	};

	const onClose = () => {
		setNewAvatar(false);
	};

	const onCrop = (preview) => {
		setAvatar(preview);
	};

	const onBeforeFileLoad = (elem) => {
		let type = elem.target.files[0].type;
		type = type.split('/')[1];
		setUploadExtFile(type == 'jpeg' ? 'jpg' : type);
	};

	const defaultAvatarBack = () => {
		setAvatar(defaultAvatar);
		setUploadExtFile('');
	};

	const changeAccountType = (val) => {
		let newVal = val - 1;
		setAccountTypeSelect(val);
		let info = `<h2>${accountTypes[newVal].name}</h2><h3>Lista Uprawnień:</h3><ul>`;
		accountTypes[newVal].permissions &&
			accountTypes[newVal].permissions.map((perm) => {
				info = `${info}<li>${perm.name}</li>`;
			});
		setInfoPermission(info);
	};
	return (
		<div className="container new-user-container">
			<div className="wrapper">
				<PageTitle title="Edytuj użytkownika" />
				<div className="col-wrapper">
					<div className="col">
						<Input
							name="login"
							label="Login"
							disabled={true}
							type="text"
							minChar={4}
							value={login}
							required
							setFunction={(val) => setLogin(val)}
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="email"
							label="Adres e-mail"
							type="email"
							value={email}
							disabled={true}
							regExp={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
							minChar={3}
							setFunction={(val) => setEmail(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="name"
							label="Imię"
							minChar={2}
							type="text"
							value={name}
							setFunction={(val) => setName(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>

						<Input
							name="surname"
							label="Nazwisko"
							type="text"
							value={surname}
							minChar={2}
							setFunction={(val) => setSurname(val)}
							required
							forceValidate={forceValidate}
							class="btn-primary btn-simple"
						/>
						{!profil &&
						<>
							<Input
								// required={true}
								name="password"
								mode="password"
								label="Hasło"
								type="password"
								value={password}
								minChar={6}
								setFunction={(val) => setPassword(val)}
								forceValidate={forceValidate}
								class="btn-primary btn-simple"
							/>
							{accountTypeSelect && optionsAccountTypes && optionsAccountTypes.length > 0 &&
								<Select
									name="account_type"
									label="Typ konta"
									data={optionsAccountTypes}
									value={accountTypeSelect}
									infoReturn={true}
									class="btn-primary btn-simple"
									handle={(val) => changeAccountType(val)}
								/>	
							}
							<div className="date-wrapper">
								<label htmlFor="">Data ważności konta</label>
								<DateTimePicker
									onChange={ val => setExpiredDate(val) }
									value={expiredDate}
									// locale="pl-PL"
									format="y-MM-dd"
									className="date-timer"
								/>
							</div>
						</>
						}

						<div className="btn-wrapper">
							<Button class="btn-primary" setFunction={() => validate()} title="Zapisz" />
						</div>
					</div>

					<div className="col">
						{/* <div className="avatar-wrap">
							{!newAvatar && (
								<div className="upload-column">
									<p className="avatar-title">Ustaw awatar</p>
									<div className="upload-btn-wrapper">
										<Button
											class="btn-secondary"
											title="Wgraj plik"
											setFunction={() => setNewAvatar(true)}
										/>

										<Button
											class="btn-secondary"
											title="Przywróć avatar"
											setFunction={() => defaultAvatarBack()}
										/>
									</div>
								</div>
							)}
							{newAvatar && (
								<div className="upload-file">
									<Avatar
										width={390}
										height={295}
										onCrop={(preview) => onCrop(preview)}
										onClose={() => onClose()}
										onBeforeFileLoad={(elem) => onBeforeFileLoad(elem)}
									/>
								</div>
							)}
							<div className="avatar">{avatar && <img src={avatar} />}</div>
						</div> */}
						{/* <hr /> */}
						<div className="info-permission" dangerouslySetInnerHTML={{ __html: infoPermission }} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditUser;
