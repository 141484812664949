import React, { useState, useEffect } from 'react'
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import MediaLoad from './../../../../components/media-load/MediaLoad';
import Input from './../../../../components/input/Input';
import SelectItems from './../../../../components/select-items/SelectItems';
import { useSelector } from 'react-redux';
import Arrow from './../../../../svg-components/Arrow';
import TrashIcon from './../../../../svg-components/TrashIcon';
import Button from './../../../../components/button/Button';
import Loader from './../../../../components/loader/Loader';
import Popup from './../../../../components/popup/Popup';
import { useDispatch } from 'react-redux';
import readTextNvda from './../../../../services/readTextNvda';
import PageTitle from './../../../../components/page-title/PageTitle';
import DateTimePicker from 'react-datetime-picker';
import Upload from './../../../../components/input/Upload';
import { upload } from '@testing-library/user-event/dist/upload';

const Excel = () => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ loader, setLoader ] = useState(true);
    let [ blockedBtn, setBlockedBtn ] = useState(false);
    let [ openBoxes, setOpenBoxes ] = useState([]);
    let [ popupText, setPopupText ] = useState(false);
    let [ uploadExcel, setUploadExcel ] = useState([]);
    let [ importInfo, setImportInfo ] = useState(false);
    let lang = useSelector(state => state.lang);

    useEffect(() => {
        if (!load) {
            ;(async function() {
                await setLoader(false);
                await setLoad(true);
            })()
        }
    },[load]);

    // const save = () => {
    //     if(!blockedBtn) {
    //         setBlockedBtn(true);
    //         axios({
    //             method: 'post',
    //             url: `${ApiUrl()}/saveSettings`,
    //             data: {
    //                 token: localStorage.getItem('token'),
    //                 userID: localStorage.getItem('userId'),
    //                 removeList: removeList,
    //                 langID: lang.id,
    //                 data: data
    //             }
    //         }).then(response => {
    //             let res = JSON.parse(response.request.response);
    //             if(res.status = "success") {
    //                 setData(res.data)
    //                 setBlockedBtn(false);
    //                 dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zmiany zostały zapisane</p>` }) 
    //             }else{
    //                 setBlockedBtn(false);
    //                 dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
    //             }
    //         })
    //     }
    // }

    const importExcel = async() => {
        if(uploadExcel.length == 0) {
            dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Wgraj plik xlsx</p>` }) 
        }else if(!blockedBtn) {
            setLoader(true);
            let fData = new FormData();

            fData.append('file', uploadExcel[0]);
            fData.append('userID', localStorage.getItem('userId'));
            fData.append('token', localStorage.getItem('token'));
            fData.append('lang', lang.id);
            await axios({
                method: 'post',
                url: `${ApiUrl()}/importMarketingSupport`,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: fData
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Excel został wgrany</p>` }) 
                    setImportInfo("Excel został wgrany")
                    setUploadExcel([])
                }else{
                    setBlockedBtn(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                    setImportInfo("Coś poszło nie tak, prosimy spróbować ponownie później")
                    setUploadExcel([])
                }
            }).catch(e => {
                setBlockedBtn(false);
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                setImportInfo("Coś poszło nie tak, prosimy spróbować ponownie później")
                setUploadExcel([])
            })
            setLoader(false);
        }
    }

    const exportExcel = async() => {
        if(!blockedBtn) {
            setLoader(true);
            let userID = localStorage.getItem('userId');
            await axios({
                method: 'get',
                url: `${ApiUrl()}/exportMarketingSupport?userID=${userID}&langID=${lang.id}`,
                headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status == "success") {
                    // dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Cache na ${WebUrl()} zostały wyczyszczone</p>` })
                    // window.location.assign(res.excel);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<a href="${res.excel}">Pobierz</a>` })
                }else{
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować później.</p>` })
                }
                setLoader(false);
            })
        }
    }

    const handleKeyDown = (e, functionName, functionValueFirst = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValueFirst, functionValueSecond)
        }
    }

    const toggleBox = (index) => {
        let status = openBoxes.includes(index);
        if(status) {
            let newOpened = openBoxes;
            let spliceIndex = newOpened.indexOf(index);
            newOpened = newOpened.filter((item, i) => i !== spliceIndex);
            setOpenBoxes(newOpened)
        }else{
            let newOpened = openBoxes;
            newOpened = [...newOpened, index];
            setOpenBoxes(newOpened);
        }
    }



    return (
        <div className="container settings-container">
            <PageTitle title="Import/Export xlsx" />
            {loader &&
                <div className="loader-wrapper">
                    <div className="loader">
                        <Loader />
                    </div>
                </div>
            }
            {popupText &&
                <Popup 
                    close={() => setPopupText(false)} 
                    editor={popupText} 
                />
            }
            <div className="settings">
                <div className={"setting" + (openBoxes.includes(0) ? " active" : "")}>
                    <div className="header"  
                        onClick={() => toggleBox(0)} 
                        onKeyDown={(e) => handleKeyDown(e, toggleBox, 0)}
                        tabIndex="0"
                        role="button"
                        aria-label={openBoxes.includes(0) ? `Zamknij Import` : `Otwórz Import`}>
                        <h2>Import</h2>
                        <div className="svg-icon">
                            <Arrow />
                        </div>
                    </div>
                    <div className="content">
                        <div className="row-section">
                            <Upload  
                                setFunction={ val => { setUploadExcel( val ); setImportInfo(false) } }
                                value={ uploadExcel }
                                regExp={ /.*/ }
                                uploadInfo
                                // forceValidate={forceValidate}
                            />
                        </div>
                        <div className="row-section save-data">
                            <Button title="Wgraj" setFunction={() => importExcel()} />
                        </div>
                        {importInfo && 
                            <div className="result-upload">
                                <p>{importInfo}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className={"setting" + (openBoxes.includes(1) ? " active" : "")}>
                    <div className="header"  
                        onClick={() => toggleBox(1)} 
                        onKeyDown={(e) => handleKeyDown(e, toggleBox, 1)}
                        tabIndex="0"
                        role="button"
                        aria-label={openBoxes.includes(1) ? `Zamknij Export` : `Otwórz Export`}>
                        <h2>Export</h2>
                        <div className="svg-icon">
                            <Arrow />
                        </div>
                    </div>
                    <div className="content">
                        <div className="row-section save-data">
                            <Button title="Wygeneruj" setFunction={() => exportExcel()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} 

export default Excel;